import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { KEY_CONTENT_ROUTES } from 'routing';
import tutorRating from 'static/img/home/tutor-rating.svg';
import meetExperience from 'static/img/home/meetExperience.svg';
import AnimatedContainer from 'components/AnimatedContainer';
import { topTutors, topSubjects } from '../../constants/topSubjects';

import css from './UkTopTutorsSubjects.module.scss';
import ukHomeCommon from 'styles/common.module.scss'
import { useTranslation } from 'react-i18next';
import { currency } from 'utils/env';
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';
function TopTutorsSubjects() {
  const { t } = useTranslation()
  return (
    <div className={css.wrapper}>
      <div className="container">
        <AnimatedContainer>
          <div className={css.topTutorsTitle}>
            {t('Meet some of our tutors with 100+ lessons')}
          </div>
        </AnimatedContainer>
        <Link to={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => {
            saveSubject("0");
            saveLevel("0");
            saveCountry("0");
          }}
        >
          <AnimatedContainer className={css.topTutorList}>
            {topTutors.map(
              ({ title, image, subject, price, mobilePrice, experience }) => (
                <div key={title} className={css.topTutorItem}>
                  <LazyLoad>
                    <img
                      className={css.topTutorItemImage}
                      src={image}
                      alt={title}
                    />
                  </LazyLoad>

                  {/* <h3 className={css.topTutorItemName}>{title}</h3> */}
                  <p className={css.topTutorItemDescr}>{subject}</p>
                  <LazyLoad>
                    <div className={css.rating}>
                     <img src={tutorRating} alt="5-stars" />
                    </div>
                  </LazyLoad>
                  <div className={css.topTutorItemLessons}>
                    {/* <span>{experience * 50}+</span> */}
                    {t('Lessons completed')}
                  </div>
                  <div className={css.topTutorItemExperience}>
                  <LazyLoad>
                    <img src={meetExperience} alt="" />
                  </LazyLoad>
                  <span> {experience} {t('years experience')}</span>
                  </div>
                  <div className={css.topTutorItemPrice}>
                    {mobilePrice && (
                      <span className={css.priceMobile}>{currency}{mobilePrice}</span>
                    )}
                    <span
                      className={cn(css.priceDesctop, {
                        [css.hasMobilePrice]: mobilePrice
                      })}
                    >
                      {currency}{price}
                    </span>
                    /{t('per hour')}
                  </div>
                </div>
              )
            )}
          </AnimatedContainer>
        </Link>
        <div className={css.topTutorsLink}>
          {/* ,ukHomeCommon.btnGradient */}
          <a className={cn("btn-home")} rel="noopener noreferrer" href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => {
              saveSubject("0");
              saveLevel("0");
              saveCountry("0");
            }}
          >
            {t('Find a tutor')}
          </a>
        </div>
        {/* <AnimatedContainer className={css.subjectsListWrap}>
          <h2 className={css.subjectsTitle}>{t('Top Subjects')}</h2>
          <ul className={css.subjectsList}>
            {topSubjects.map(({ title, link }) => (
              // ,ukHomeCommon.btnGradient
              <li key={link} className={cn(css.subjectsListItem)}>
                <a href={`//tutorhouse.co.uk${link}`}>{t(title)}</a>
              </li>
            ))}
          </ul>
        </AnimatedContainer> */}
      </div>
    </div>
  );
}

export default TopTutorsSubjects;
