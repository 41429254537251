import { Dispatch, useMemo, useReducer } from 'react';
import { SearchResultsActionType, setPage } from './actions';
import { logSafelyToConsole } from 'utils';
import { wrapActions } from 'utils/reducerHelpers';
import { SubjectGroup, Level, SelectedSubject, ITutorProfileListItem, AnyAction } from 'types';

const {
  SEARCH_TUTORS_REQUEST,
  SEARCH_TUTORS_SUCCESS,
  SEARCH_TUTORS_ERROR,
  GET_MORE_TUTORS_REQUEST,
  GET_MORE_TUTORS_SUCCESS,
  GET_MORE_TUTORS_ERROR,
  SET_PAGE,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_ERROR,
  CHANGE_SUBJECT
} = SearchResultsActionType;
export interface SearchResultsState {
  loading: boolean;
  tutors: ITutorProfileListItem[];
  totalCount: number | undefined;
  location?: string | undefined;
  subjectGroups: SubjectGroup[];
  selectedSubject: SelectedSubject | null;
  selectedLevel: Level | null;
  subjectLoading: boolean;
}

export interface SearchResultsDispatchPayload {
  tutors: ITutorProfileListItem[];
}

export default function reducer(
  state: SearchResultsState,
  action: {
    type: SearchResultsActionType;
    payload: any;
  }
): SearchResultsState {
  const { type, payload } = action;

  logSafelyToConsole(type);
  switch (type) {
    case SEARCH_TUTORS_REQUEST: {
      return {
        ...state,
        tutors: [],
        loading: true,
        totalCount: 0,
        selectedSubject: payload.selectedSubject,
        selectedLevel: payload.selectedLevel,
      };
    }
    case SEARCH_TUTORS_SUCCESS: {
      if (payload) {
        const { tutors, totalCount, location } = payload;
        return {
          ...state,
          loading: false,
          tutors,
          totalCount,
          location: location ? location : '',
        };
      } else return state;
    }
    case SEARCH_TUTORS_ERROR: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_MORE_TUTORS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_MORE_TUTORS_SUCCESS: {
      if (payload) {
        const { tutors, totalCount } = payload;

        return {
          ...state,
          loading: false,
          tutors: tutors,
          totalCount: totalCount ? totalCount : state.totalCount,
        };
      } else return state;
    }
    case GET_MORE_TUTORS_ERROR: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SUBJECTS_REQUEST: {
      return {
        ...state,
        subjectLoading: true,
      }
    }

    case GET_SUBJECTS_SUCCESS: {
      return {
        ...state,
        subjectGroups: payload.subjectGroups,
        selectedSubject: payload.selectedSubject,
        selectedLevel: payload.selectedLevel,
        subjectLoading: false,
      }
    }

    case GET_SUBJECTS_ERROR: {
      return {
        ...state,
        subjectLoading: false,
      }
    }

    case CHANGE_SUBJECT: {

      return {
        ...state,
        selectedSubject: payload.subject,
        selectedLevel: payload.level,
      }
    }
    default:
      return state;
  }
}

interface PaginatorReducerState {
  page: number,
}

const paginatorReducerStateInitial: PaginatorReducerState = {
  page: 1,
};

const paginatorReducer = (
  state: PaginatorReducerState,
  action: AnyAction<SearchResultsActionType>
  ): PaginatorReducerState => {
  const {type} = action;
  switch (type) {
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
};

export const usePaginatorReducer = (
  initial: Partial<PaginatorReducerState> = {}
): {
  dispatch: Dispatch<AnyAction<SearchResultsActionType>>,
  state: PaginatorReducerState,
  actions: any,
} => {
  const [state, dispatch] =
    useReducer(paginatorReducer, {...paginatorReducerStateInitial, ...initial}) as
      [PaginatorReducerState, Dispatch<AnyAction<SearchResultsActionType>>];

  const wrappedActions = useMemo(
    () => wrapActions({
      setPage
    }, dispatch),
    [dispatch]
  );

  return {
    dispatch,
    state,
    actions: wrappedActions
  };
};
