import { IFAQ } from "components/FAQPinkAccordion/types";
import i18n from 'i18next';
export const faq: IFAQ[] = [{
  name: i18n.t('What subjects and ages does Tutor House cover?'),
  text: [i18n.t('We offer lessons in pretty much every subject')],
},
{
  name: i18n.t('How much does a tutor cost?'),
  text: [i18n.t('All private tutors cost from £20 to £90 per hour')],
},
{
  name: i18n.t('Why should I get a private tutor?'),
  text: [i18n.t(`We’ve seen time and time again that private tuition gives students better grades, subject-knowledge and even career opportunitiesSo now this option is fully available to you at a much more flexible rate, it’s silly not to make the most of itOur students come from all walks of life — but all of them get exactly the same attention to improve their education`)],
},
{
  name: i18n.t('When can I start lessons?'),
  text: [i18n.t(`As soon as you like! We can usually organise online tutoring to start within 24 hours! Once you’ve matched with your tutor and signed up, you’ll have 24/7 access to your tutor’s diarySo you can book lessons ASAP`)],
},
{
  name: i18n.t('How easy is it to learn online?'),
  text: [i18n.t(`Online learning is very easyEven if you’re not the most techy, don’t fret! We’ll give you a demo of the eWhiteboard and show you exactly how to use itWe have our own online whiteboard and interactive learning platform, which is free and simple to use`)],
},
{
  name: i18n.t('How many lessons will I need?'),
  text: [i18n.t(`We recommend that students start with 20 or more lessonsOf course this depends on each individual’s needsBut our research shows that this is the best number of lessons to achieve learning goals and target gradesRead our industry report here to find out why`)],
},
{
  name: i18n.t('What’s included in the TH membership?'),
  text: [i18n.t(`Our bulk-buy package includes One Lesson Free and unlimited access to the eWhiteboard featuresThis includesrecording sessions to playback later, a weekly report of your progress and other exclusive featuresYou’ll get access to free weekly resourcesblogs, newsletters, past papers, exemplar essays and more! We’ll also keep you up to date on industry news and any upcoming events we’re hosting`)],
},
{
  name: i18n.t('How will I be charged for lessons?'),
  text: [i18n.t(`We’ll ask for your card details when you register with Tutor HouseAs soon as you complete a lesson, you’ll be automatically chargedAll payments are cashless for your convenience and you’ll receive a bill instantly to your email addressYou can track all payments – and there are no extra fees!`)],
},
{
  name: i18n.t('Can I change my tutor?'),
  text: [i18n.t(`Absolutely! What’s important to us is that you’re getting the most out of your private tuitionSo if you want to swap your tutor for someone else, no problem`)],
},
{
  name: i18n.t('Do your tutors have a DBS check?'),
  text: [i18n.t(`Mostly all of our tutors have a DBS checkIt will say on the profile of the specific tutor if they’ve had their DBS check or notWe interview all tutors and all tutors have to go through training`)],
}];
