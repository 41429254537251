import React from 'react';
import StarRating from 'components/StarRating';
import { IGoogleReview } from '../../../constants/GoogleReviews';
import css from './GoogleReviewCard.module.scss';
import { useTranslation } from 'react-i18next';

export default function GoogleReviewCard({
  img,
  name,
  rating,
  time,
  positive,
  description,
  link
}: IGoogleReview) {
  const { t } = useTranslation()
  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <div className={css.img}>{img}</div>
        <div className={css.name}>{name}</div>
      </div>
      <div className={css.subtitle}>
        <div className={css.rating}><StarRating rating={rating} fontSize={1}/><span>{time}</span></div>
        <div className={css.positive}>{t('Positive')}: <span>{positive}</span></div>
      </div>
      <div className={css.description}>
        {description}
        <a href={link} target="_blank">{t('More')}</a>
      </div>
    </div>
  )
}
