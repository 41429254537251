import React from 'react'
import cn from 'classnames';
import css from './classesBySubject.module.scss'
import subjectData from './subjectData'
import ukHomeCommon from 'styles/common.module.scss'
import { Col, Row} from 'antd';
import { useTranslation } from 'react-i18next'
import { saveSubject, saveLevel } from 'utils/useSessionStorage';
import { History } from 'history';
import { KEY_CONTENT_ROUTES } from 'routing';

interface Props {
  history: History;
}
function ClassesBySubject({ history }: Props) {
  const { t } = useTranslation()
  const gotoSearch=(subjectId?:string,levelId?:string)=>{
    saveSubject(subjectId?subjectId:'')
    saveLevel(levelId?levelId:'')
    history.push(KEY_CONTENT_ROUTES.SEARCH_RESULTS)
  }
  return (
    <div className={cn(css.classSujectContainer,ukHomeCommon.ukhomeWrapPadding)}>
      <div className={css.sectionTitle}>Discover Tutors by Subject</div>
      <div className={css.sectionWarp}>
        <Row gutter={48}>
          {subjectData.map((item,index)=>{
              return (
                <Col xl={6} md={12} className={css.categaryItem} key={index}> 
                    <div className={css.categary}>
                       <img  src={item.icon} alt={item.id} />
                      <span>{item.categary}</span>
                    </div>
                    <div className={css.subjectItem}>
                      {item.child.map((childItem,childIndex)=>{
                        return (
                          <div key={childIndex}>
                            <li>
                              <a className={css.subjectName} onClick={()=>gotoSearch(childItem.id, '0')}>{childItem.name}</a>
                            </li>
                          </div>
                        )
                      })}
                    </div>
                </Col>
              )
          })}
        </Row>
      </div>
  </div>
  )
}

export default ClassesBySubject
