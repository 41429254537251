import React from 'react';
import { Flex, Box, Text } from 'rebass';
import FilledStar from './FilledStar';
import UnfilledStar from './UnfilledStar';

export default function StarRating({
  rating,
  score,
  fontSize,
  distanceBetweenStars,
  color,
  scoreColor = '#130217',
  sx = {},
  onChange = () => {}
}: {
  rating: number;
  score?: number;
  fontSize?: number;
  distanceBetweenStars?: number;
  color?: string;
  scoreColor?: string;
  sx?: object;
  onChange?: (rating: number) => void;
}) {
  return (
    <Flex alignItems="center" sx={sx}>
      <Box>
          {
            Array.from({ length: 5 }).map((_, ind) => {
              return rating >= (ind + 1)
                ? <FilledStar key={ind} onClick={() => onChange(ind + 1)} distanceBetweenStars={distanceBetweenStars} fontSize={fontSize} color={color}/>
                : <UnfilledStar key={ind} onClick={() => onChange(ind + 1)} distanceBetweenStars={distanceBetweenStars} fontSize={fontSize} color={color} />
            })
          }
      </Box>
      {score !== undefined && (
        <Text
          ml={2}
          fontSize={`${fontSize}rem`}
          color={scoreColor}
          sx={{opacity: '0.8'}}
        >
          ({score})
        </Text>
      )}
    </Flex>
  );
}
