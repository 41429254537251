import { Dispatch, Reducer } from 'react';
import { Action, ActionCreatorsMapObject, AnyAction } from 'types/Reducer';

const wrapAction = (dispatch: Dispatch<Action>, action: any, args: any[]) => {
  const result = action(...args);
  if (typeof result === 'function') {
    return result(dispatch);
  }
  if (result instanceof Promise) {
    return result.then(func => func(dispatch));
  }
  return dispatch(result);
};

export const wrapActions = (
  actions: ActionCreatorsMapObject,
  dispatch: Dispatch<AnyAction>
) => {
  const result: any = {};
  for (const key in actions) {
    result[key] = (...args: any[]) => wrapAction(dispatch, actions[key], args);
  }
  return result;
};

export const loadReducerState = (key: string) => {
  try {
    const data = window.localStorage.getItem(key);
    if (!data) {
      return undefined;
    }
    const parsedData: {content: any, expiration: string} = JSON.parse(data);
    if (parsedData.expiration) {
      const now = new Date();
      const expiration = new Date(parsedData.expiration);
      if (now.getTime() > expiration.getTime()) {
        window.localStorage.removeItem(key);
        return undefined;
      }
    }
    return parsedData.content;
  } catch (err) {
    return undefined;
  }
};

export const saveReducerState = (key: string, state: object, expiration: number = 0) => {
  try {
    const serializedState = JSON.stringify(
      {
        content: state,
        expiration: expiration > 0 ? (new Date().getTime() + expiration * 60000) : undefined,
      });
    window.localStorage.setItem(
      key,
      serializedState
    );
  } catch(err) {
    console.log(err);
  }
};

export const persistedReducer = <State = any, ActionType = any>({
  reducer, storageKey, expirationTime, stateGetter = (v: State) => v
}: {
  reducer: Reducer<State, AnyAction<ActionType>>,
  storageKey: string,
  stateGetter?: (v: State) => Partial<State>,
  expirationTime?: number
}) => {
  return (state: State, action: AnyAction<ActionType>) => {
    const nextState = reducer(state, action);
    saveReducerState(
      storageKey,
      stateGetter(nextState),
      expirationTime
    );
    return nextState;
  }
};
