import React from 'react';
import { KEY_CONTENT_ROUTES } from 'routing';
import cn from 'classnames';
import desktop from 'static/img/home/onlieLeesons.png';
import ukHomeCommon from 'styles/common.module.scss'
import css from './UkOnlineLessonsBlock.module.scss';
import { useTranslation } from 'react-i18next';

function OnlineLessonsBlock () {
  const { t } = useTranslation()
  return (
    <section className={cn(css.wrapper)}>
      {/* <h3 className={cn(css.title, css.mobile)}>{t('Award winning online lessons')}</h3> */}

      <div className={css.leftSide}>
        <img src={desktop} alt='' />
      </div>
      <div className={css.rightSide}>
        {/* , css.desktop */}
        <h3 className={cn(css.title)}>{t('Award winning online lessons')}</h3>
        <p className={css.description}>
          <span>{t('Our virtual experience makes online learning easy and fun All tutors are trained and qualified to deliver high-quality lessons to you anywhere in the UK')} </span>
          <span>{t('Your online lessons are tailored to your specific requirements, ensuring you meet your expectations and achieve your goals')}</span>
        </p>
        {/* ,ukHomeCommon.btnGradient */}
        <a className={cn(css.startedBtn, 'btn-primary')} rel="noopener noreferrer" href={KEY_CONTENT_ROUTES.SEARCH_RESULTS}>{t('Get started')}</a>
      </div>
    </section>
  );
}
export default OnlineLessonsBlock;
