import React,{useContext} from 'react';
import css from './UaeGradeImprovementBlock.module.scss';
import { useTranslation } from 'react-i18next';
import { KEY_CONTENT_ROUTES } from '../../../../routing';
import uaeBe1 from 'static/img/home/gradeImprevoment/TrainedPic.png'
import uaeBe2 from 'static/img/home/gradeImprevoment/InteractivePic.png'
import uaeBe3 from 'static/img/home/gradeImprevoment/HiddenPic.png'
import LazyLoad from 'react-lazyload';
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';
import cn from 'classnames';
import { isCN } from 'utils/env';
import AuthContext from 'context/AuthContext';

function GradeImprovementBlock() {
  const { t } = useTranslation()
  const auth = useContext(AuthContext);

  const uaeTutorBenefits = [
    {
      img: uaeBe1,
      title: 'Trained & Experienced'
    },
    {
      img:uaeBe2,
      title: 'Interactive Live Lessons'
    },
    {
      img: uaeBe3,
      title: 'No Hidden Fees'
    }
  ];
  return (
    <div className={css.wrapper}>
        <div className={css.textWarp}>
          <div className={css.onlyAccept}>
          {t('We only accept the top 20% of applicants as tutors')}
          </div>
          <div className={css.ourOline}> {t('Our online tutors are trusted by parents and recommended by students')}</div>
          <a  
              className={css.findTutor}
              href={KEY_CONTENT_ROUTES.SEARCH_RESULTS}
              rel="noopener noreferrer"
              onClick={() => {
                saveSubject("0");
                saveLevel("0");
                saveCountry("0");
              }}
            >
              {t('Find a Tutor')}
          </a>
        </div>
        <div className={css.picsWarp}>
          {uaeTutorBenefits.map((el,index)=>(
            <div key={index} className={cn([
              isCN ? css.itemWarpCN : css.itemWarp, 
              !isCN && css.itemWarpUae
            ])}>
              <div className={css.itemImg}>
                <LazyLoad >
                <img src={el.img} alt={el.title} />
                </LazyLoad>
              </div>
              <div className={css.itemHeader}>
                <span>{t(el.title)}</span>
              </div>
            </div>
          ))
          }
         </div>
        {!isCN &&!auth.user&& <div className={css.getStartedNowContainer}>
          <div className={css.preTitle}>{t('Sign Up for FREE')}</div>
          <div className={css.title}>{t('GET STARTED NOW!')}</div>
          <div className={css.description}>
            {t('After signing up you can book FREE trials and/or book regular sessions Our customers success team will be in touch as well, stay tuned!')}
          </div>
          <a  
              className={css.signUpButton}
              href={KEY_CONTENT_ROUTES.STUDENT_CREATE_ACCOUNT}
              rel="noopener noreferrer"
            >
              {t('Sign Up')}
          </a>
        </div>}
    </div>
  );
}

export default GradeImprovementBlock;
