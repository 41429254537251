import React from 'react';
import { howItWorksSteps } from 'pages/Homepage/constants/howItWorks';
import css from './UaeHowItWorks.module.scss';
import { useTranslation } from 'react-i18next';
import RectangletwoPeople from 'static/img/home/howItWorks/RectangletwoPeople.svg';
import kid from 'static/img/home/howItWorks/kid.png';
import { isCN } from 'utils/env';
import {CompletedIcon} from './svg'
import { Col, Row } from 'antd';
import cn from 'classnames';
import i18n from 'utils/i18n';
import { KEY_CONTENT_ROUTES } from 'routing';
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';

function UaeHowItWorks () {
  const { t } = useTranslation()
  return (
    <section className={css.wrapper}>
      <div className={css.container}>
        {!isCN && <div className={css.showcase}>
          <div className={css.showcaseItem}>
            <div>{t('Tutors')}</div>
            <div dir='ltr'>10,000+</div>
          </div>
          <div className={css.showcaseItem}>
            <div>{t('Subjects')}</div>
            <div dir='ltr'>100+</div>
          </div>
        </div>}
        <div className={css.visitour}>{t('ONLINE AND IN-PERSON 1-ON-1 OR COLLABORATIVE GROUP SESSIONS')}</div>
        {/* <div className={css.visitour}>{t('Visit our booth at GESS Dubai 2023')} <a href='https://www.gessdubai.com/' target='_blank'>GESS Dubai 2023</a> {t('FREE TO ATTEND')} {t('30 October')}</div> */}
        <div className={css.featureWarp}>
          <div className={css.features}>
            <span className={css.featureItem}>{CompletedIcon} <span className={cn([css.featuresText, i18n.isAr && css.spanAr])}>{t('PREMIUM PRIVATE TUTORING PLATFORM')}</span></span>
            <span className={css.featureItem}>{CompletedIcon} <span className={cn([css.featuresText, i18n.isAr && css.spanAr])}>{t('TOP CERTIFIED TUTORS')} </span></span>
            <span className={css.featureItem}>{CompletedIcon} <span className={cn([css.featuresText, i18n.isAr && css.spanAr])}>{t('FREE TRIAL BOOKINGS')}</span></span>
          </div>
          <div className={css.features}>
            <span className={css.featureItem}>{CompletedIcon} <span className={cn([css.featuresText, i18n.isAr && css.spanAr])}>{t('CONNECT FROM ANYWHERE LEARN EVERYWHERE')}</span></span>
            <span className={css.featureItem}>{CompletedIcon} <span className={cn([css.featuresText, i18n.isAr && css.spanAr])}>{t('ADAPTABLE TIME-SCHEDULING TO SUIT YOUR NEEDS')} </span></span>
            <span className={css.featureItem}>{CompletedIcon} <span className={cn([css.featuresText, i18n.isAr && css.spanAr])}>{t('TAILORED LEARNING EXPERIENCES')}</span></span>
          </div>
        </div>
        {!isCN && <div className={css.popularSubjectsContainer}>
          <div className={css.title}>{t('The most popular subjects')}</div>
          <div className={css.linksContainer}>
            <div className={css.linksBar}>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('4')}>{t('Maths')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('1')}>{t('English')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('10')}>{t('Science')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('2')}>{t('Arabic')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('9')}>{t('Business')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('28')}>{t('French')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('3')}>{t('Physics')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('6')}>{t('Chemistry')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('36')}>{t('Geography')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('27')}>{t('Spanish')}</a>
              <a href={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => saveSubject('26')}>{t('Italian')}</a>
            </div>
            {/* <div className={css.linksBar}>
              <a >{t('Maths')}</a>
              <a >{t('English')}</a>
              <a >{t('Science')}</a>
              <a >{t('Arabic')}</a>
              <a>{t('Business')}</a>
              <a >{t('French')}</a>
              <a >{t('Physics')}</a>
              <a >{t('Chemistry')}</a>
              <a >{t('Geography')}</a>
              <a >{t('Spanish')}</a>
              <a >{t('Italian')}</a>
            </div> */}
            <a  
              className={css.findTutor}
              href={KEY_CONTENT_ROUTES.SEARCH_RESULTS}
              rel="noopener noreferrer"
              onClick={() => {
                saveSubject("0");
                saveLevel("0");
                saveCountry("0");
              }}
            >
              {t('Find Tutors')}
            </a>
          </div>
        </div>}
        <div className={css.title}>{t('How it works')}</div>
        <div className={css.description}>
          <div className={css.Personalised}>{t('Personalised online tuition to boost grades and ')}{t('confidence')}.</div>
          <div className={css.improveyour}>{t('Improve your')} {t('grades by 3x')} {t('with lessons that match your needs and learning goals')}</div>
        </div>
        <div className={css.steps}>
          {howItWorksSteps.map(el => (<div key={el.title} className={css.step}>
           <img src={el.icon} alt='' />
            <div className={css.stepTitle}>{t(el.title)}</div>
          </div>))}
        </div>
        <div className={css.twoPeople}>
          {
          isCN 
          ? <div className={css.cnPeopleRow}>
              <div className={css.cnPeopleLeftColumn}>
                <div className={css.cnPeopleTitle}>{t('Learn Mandarin')}</div>
                <div className={css.cnPeopleTitle}>{t('Expand Your Horizons')}</div>
                <div className={css.cnPeopleSubtitle}>{t('START LEARNING MANDARIN TODAY AND JOIN A GLOBAL COMMUNITY')}</div>
              </div>
              <img src={kid}/>
            </div> 
          : <img src={RectangletwoPeople} alt=''/>
          }
        </div>
      </div>
    </section>
  );
}
export default UaeHowItWorks;
