import bbc from 'static/img/home/pressSlider/ukbbc.svg';
import dailyTelegraph from 'static/img/home/pressSlider/ukdailyTelegraph.svg';
import eveningStandard from 'static/img/home/pressSlider/ukeveningStandard.svg';
import guardian from 'static/img/home/pressSlider/ukguardian.svg';
import itv from 'static/img/home/pressSlider/ukitv.svg';
import metro from 'static/img/home/pressSlider/ukmetro.svg';
import techCrunch from 'static/img/home/pressSlider/uktechCrunch.svg';
import tes from 'static/img/home/pressSlider/uktes.svg';

interface IPressSlider {
  img: string;
  label: string;
}

export const pressSlider: IPressSlider[] = [
  {
    label: 'theguardian',
    img: guardian
  },
  {
    label: 'bbc',
    img: bbc
  },
  {
    label: 'dailyTelegraph',
    img: dailyTelegraph
  },
  {
    label: 'metro',
    img: metro
  },
  {
    label: 'eveningStandard',
    img: eveningStandard
  },
  {
    label: 'itv',
    img: itv
  },
  {
    label: 'techCrunch',
    img: techCrunch
  },
  {
    label: 'tes',
    img: tes
  }
];

export const carouselConfigs = {
  dots: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  infinite: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1560,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 915,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 776,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true
      }
    }
  ]
};


