/**
 * Calculate offset for element (offset func in JQuery)
 * @param { HTMLDivElement } elem  -- HTML container
 * @returns { top: number, left: number } position for element
 */
export function getOffsetRect(elem: HTMLDivElement | null): { top: number, left: number } {
  if(!elem) { return { top: 0, left: 0 }; }
  const { top, left } = elem.getBoundingClientRect();
  const { body, documentElement } = document;
  const { pageYOffset, pageXOffset } = window;

  const scrollTop = pageYOffset || documentElement.scrollTop || body.scrollTop;
  const scrollLeft = pageXOffset || documentElement.scrollLeft || body.scrollLeft;

  const clientTop = documentElement.clientTop || body.clientTop || 0;
  const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

  return {
    top: Math.round(top +  scrollTop - clientTop),
    left: Math.round(left + scrollLeft - clientLeft)
  }
}

/**
 * Check if element inside viewport
 * @param element HTML Element
 * @param offset -- height in px for increasing element height
 * @returns true if element inside viewport
 */
export function checkIfInView (element: HTMLDivElement | null, offset = 0): boolean {
  const { innerHeight: windowHeight, pageYOffset: windowTopPosition } = window;
  const windowBottomPosition = (windowTopPosition + windowHeight);

  if (!element) {
    return false;
  }

  const elementHeight = element.offsetHeight;
  const elementTopPosition = getOffsetRect(element).top;
  const elementBottomPosition = (elementTopPosition + elementHeight);

  return [
    elementBottomPosition - offset >= windowTopPosition,
    elementTopPosition <= windowBottomPosition + offset
  ].every(e => e);
}
