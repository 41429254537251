import React from 'react';
import cn from 'classnames';
import AnimatedContainer from 'components/AnimatedContainer';
import Slider from 'react-slick';
import { carouselConfigs, pressSlider } from '../../constants/ukPressSilder';
import css from './PressSlider.module.scss';
import './press-slider.scss';

function PressSlider() {
  return (
    <>
      <div className={css.wrapper}>
        <div className="container">
          <Slider className="homepage-logo-press-slider" {...carouselConfigs}>
            {pressSlider.map(({ label, img }) => (
              <AnimatedContainer key={label}>
                <div className={css.sliderItem}>
                  <img src={img} alt={label} />
                </div>
              </AnimatedContainer>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default PressSlider;
