import React,{useRef,useEffect} from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import GoogleIcon from 'components/IconModule/icons/google';
import StarRating from 'components/StarRating';
import AnimatedContainer from 'components/AnimatedContainer';
import GoogleReviewCard from './GoogleReviewCard/index';
import { GoogleReviews, GoogleReviewCarouselConfig } from '../../constants/GoogleReviews';
import css from './GoogleReview.module.scss';
import './press-slider.scss';
import { useTranslation } from 'react-i18next';
import ukarrowReview from 'static/img/home/pressSlider/ukarrowReview.svg'
import googleView from 'static/img/googleView.svg'

export default function GoogleReview() {
  const { t } = useTranslation()
  const googleRef = useRef<Slider>(null)
  const preArrowClick=()=>{
    googleRef.current&&googleRef.current.slickPrev()
  }
  const nextArrowClick=()=>{
    googleRef.current&&googleRef.current.slickNext()
  }
  // useEffect(() => {
  //   googleRef.current&&googleRef.current.slickGoTo(0.5)
  // }, []);
  return (
    <div className={css.wrapper}>
      <div className={css.titleBlock}>
       <div className={css.logo}>
          <img src={googleView} alt=''/>
          {/* <GoogleIcon />
          <StarRating rating={5} fontSize={1.5} /> */}
        </div>
        <div className={css.title}>{t('View all of our Google Reviews')} </div>
      </div>
      <div className={css.sliderWrapper}>
        <Slider
          ref={googleRef}
          {...GoogleReviewCarouselConfig}
          appendDots={dots=>{
            return(
              <div>
                <img onClick={preArrowClick} className={css.preArrow} src={ukarrowReview}/>
                {dots}
                <img onClick={nextArrowClick} className={css.nextArrow} src={ukarrowReview}/>
              </div>
            )}}
          className={cn(css.slider, "Googlepress-slider")}
        >
          {GoogleReviews.map((item, i) => (
            <AnimatedContainer key={i}>
              <GoogleReviewCard {...item}/>
            </AnimatedContainer>
          ))}
        </Slider>
      </div>
    </div>
  )
}
