import React from 'react';
import { howItWorksStepsNewUk } from 'pages/Homepage/constants/howItWorks';
import css from './UkHowItWorks.module.scss';
import { useTranslation } from 'react-i18next';

function HowItWorks () {
  const { t } = useTranslation()
  return (
    <section className={css.wrapper}>
      <div className={css.container}>
        <h2 className={css.title}>{t('How it works')}</h2>
        <div className={css.description}>
          <div>{t('Personalised online tuition to boost grades and ')}<b>{t('confidence')}</b></div>
          <div>{t('Improve your')} <b>{t('grades by 3x')}</b> {t('with lessons that match your needs and learning goals')}</div>
        </div>
        <div className={css.steps}>
          {howItWorksStepsNewUk.map(el => (<div key={el.title} className={css.step}>
            <img src={el.icon} alt='' />
            <div className={css.stepTitle}>{t(el.title)}</div>
          </div>))}
        </div>
      </div>
    </section>
  );
}
export default HowItWorks;
