import React, { useContext,useEffect, useRef } from 'react';
import { KEY_CONTENT_ROUTES } from '../../../../routing';

// import CompletedIcon from 'components/IconModule/icons/completed';
import css from './uaeHero.module.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import circleGreen from 'static/img/home/hero/circleGreen.svg'
import circleRed from 'static/img/home/hero/circleRed.svg'
import cnHeroPic from 'static/img/home/hero/cnHeroPic.jpg'
import circlePeople from 'static/img/home/hero/circlePeople.svg'
import circleLine from 'static/img/home/hero/circleLine.svg'
import {CompletedIcon} from './svg'
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';
import { isCN } from 'utils/env';
import cn from 'classnames';
import AuthContext from 'context/AuthContext';

const UaeHero = () => {
  const { t } = useTranslation()
  const auth = useContext(AuthContext);

  let interval: any;
 
  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });
  return (
    <div className={css.heroWrap} >
      {/* {!isCN && <div className={css.headerLink}>
        <a href={'https://oktopi.ai/en/blog/oktopi-and-itworx-present-nadwa-the-uaes-premier-tutoring-marketplace/'}>{t('Nadwa Marketplace Officially Released')}</a>
      </div>} */}
      {/* <div className={css.visitour}>{t('Visit our booth at GESS Dubai 2023')} <span style={{color:'red'}}>{t('FREE TO ATTEND')}</span> {t('30 October')}</div> */}
      <div className={css.mainContentWarp}>
        <div className={css.leftWarp}>
          <div className={css.heroText}>{t('Achieve Academic Excellence With Trusted Tutors From The UAE and UK')}</div>
          <div className={css.heroDescr}>{t('Customized learning experiences, online or onsite aligned with your educational preferences')}</div>
          {!isCN&&<a
            className={cn({[css.heroLinks]: true, [css.signUpFreeButton]:true})}
            href={auth.user ? KEY_CONTENT_ROUTES.SEARCH_RESULTS : KEY_CONTENT_ROUTES.STUDENT_CREATE_ACCOUNT}
            rel="noopener noreferrer"
            onClick={auth.user ? () => {
              saveSubject("0");
              saveLevel("0");
              saveCountry("0");
            } : () => {}}
          >
            {t(auth.user ? 'Find a Tutor' : 'Sign Up for FREE')}
          </a>}
          {/* <div className={css.features}>
            <span className={css.featureItem}>{CompletedIcon} <span>{t('First Lesson FREE')}</span></span>
            <span className={css.featureItem}>{CompletedIcon} <span>{t('Trained & Qualified Tutors')} </span></span>
            <span className={css.featureItem}>{CompletedIcon} <span>{t('No Commitment')}</span></span>
          </div> */}
        </div>
        <div className={css.contentRight}>
          <div className={css.contentRightPic}>
            {isCN ? <img src={cnHeroPic} style={{width: '100%'}} alt='cnHeroPic'/> 
            : <>
              <img className={css.red} src={circleRed} alt="circleRed" />
              <div className={css.peopleWarp}>
                <div className={css.people}>
                  <img className={css.circleLine} src={circleLine} alt="circleLine"/>
                  <img className={css.peopleImg} src={circlePeople} alt="circlePeople" />
                  <img className={css.green} src={circleGreen} alt="circleGreen" />
                </div>
              </div>
            </>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UaeHero;
