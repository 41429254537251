import React, { useEffect, useRef, ReactNode, useState, CSSProperties, useCallback } from 'react';
import cn from 'classnames';
import { checkIfInView } from 'utils/dom';
import css from './AnimatedContainer.module.scss';

interface IAnimatedContainerProps {
  children: ReactNode;
  className?: string;
  multiple?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

function AnimatedContainer({ children, className, multiple = false, style, onClick = () => {} }: IAnimatedContainerProps) {
  const element = useRef<HTMLDivElement>(null);
  const [inView, setInView] = useState(false);
  const [firstScroll, setFirstScroll] = useState(true);

  const onScroll = useCallback(() => {
    if (firstScroll) {
      setFirstScroll(false);
    }
    if (multiple) {
      const isElementInView = checkIfInView(element.current, 100);
      setInView(isElementInView);
    } else if(!inView && checkIfInView(element.current, 100)){
      setInView(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  return (
      <div ref={element}
        style={style}
        onClick={onClick}
        className={cn(
          className,

          css.body,
          {
            [css.inView]: inView,
            [css.opacity]: firstScroll
           },
          )}
      >
        {children}
      </div>
  );
}

export default AnimatedContainer;
