import React from 'react';
import { Icon } from 'antd';

export default function FilledStar({
  fontSize = 1.5,
  distanceBetweenStars = 0,
  color = 'gold',
  onClick = () => {},
}:{
  fontSize?: number;
  distanceBetweenStars?: number;
  color?: string;
  onClick?: () => void;
}) {
  return (
    <Icon
      style={{ color: color, fontSize: `${fontSize}rem`, marginLeft: distanceBetweenStars} }
      type="star"
      theme="filled"
      onClick={onClick}
    />
  );
}
