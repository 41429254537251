import i18n from 'i18next';
import step1 from 'static/img/home/howItWorks/step1.svg';
import step2 from 'static/img/home/howItWorks/step2.svg';
import step3 from 'static/img/home/howItWorks/step3.svg';
import uaestep1 from 'static/img/home/howItWorks/uaeStep1.svg';
import uaestep2 from 'static/img/home/howItWorks/uaeStep2.svg';
import uaestep3 from 'static/img/home/howItWorks/uaeStep3.svg';
import ukstep1 from 'static/img/howItWorks/worksOne.svg';
import ukstep2 from 'static/img/howItWorks/worksTwo.svg';
import ukstep3 from 'static/img/howItWorks/worksTree.svg';
import { isUAE } from 'utils/env';
interface IHowItWorkStep {
  icon: string;
  title: string;
}

export const howItWorksSteps: IHowItWorkStep[] = [{
  icon: isUAE ? uaestep1 : step1,
  title: 'Find your perfect online tutor',
},
{
  icon: isUAE ? uaestep2 : step2,
  title: 'Start with a FREE online trial lesson',
},
{
  icon: isUAE ? uaestep3 : step3,
  title: 'Sign up today to receive discounts on packages',
}];
export const howItWorksStepsNewUk: IHowItWorkStep[] = [{
  icon:  ukstep1,
  title: 'Find your perfect online tutor',
},
{
  icon: ukstep2,
  title: 'Start with a FREE online trial lesson',
},
{
  icon:  ukstep3,
  title: 'Become a member and save time and money',
}];
