import React, { useEffect, useState, useRef } from 'react';
import css from './FindFilter.module.scss';
import cx from 'classnames';
import { Form, Select, Slider, Spin, Input,Radio } from 'antd';
import ContentEditable  from 'react-contenteditable';
import { Level, SubjectGroup, SelectedSubject } from 'types';
import {
  getSavedMaxExperience,
  getSavedMaxPrice,
  getSavedMinExperience,
  getSavedMinPrice,
  getSavedPostcode,
  getSavedSearchTutorKeyword,
  getSavedTutoringType,
  saveLevel,
  saveMaxExperience,
  saveMaxPrice,
  saveMinExperience,
  saveMinPrice,
  savePostcode,
  saveSearchTutorKeyword,
  saveSubject,
  saveCategory,
  saveTutoringType,
  saveCourseCategory,
  getCourseCategory
} from 'utils/useSessionStorage';
import { experienceOptions, isValidPostcode } from 'utils';
import { SliderValue } from 'antd/lib/slider';

import vectorarrow from 'static/img/home/arrow-drop-down-circle.svg';
import { useTranslation } from 'react-i18next';
import { currency, isUAE } from 'utils/env';
import i18n from 'utils/i18n';
import { KEY_CONTENT_ROUTES } from 'routing';
import cn from 'classnames';
interface Props {
  requestNewSearch: () => void;
  requestCourseSearch: () => void;
  subjectGroups: SubjectGroup[];
  loading?: boolean;
  defaultSubject: SelectedSubject | null;
  defaultLevel: Level | null;
  activekey:string;
}

export default function FindFilters(props: Props) {

  const {
    requestNewSearch,
    requestCourseSearch,
    subjectGroups = [],
    loading = false,
    defaultSubject,
    defaultLevel,
    activekey
  } = props;

  const timer = useRef<number | null>(null);

  const marks = {
    20: currency + '20',
    30: currency + '30',
    40: currency + '40',
    50: currency + '50',
    60: currency + '60',
    70: currency + '70',
    80: currency + '80',
    90: currency + '90',
  };
  const uaeMarks = {
    50: currency + '50',
    100: currency + '100',
    150: currency + '150',
    200: currency + '200',
    250: currency + '250',
    300: currency + '300',
    350: currency + '350',
    400: currency + '400',
  };

  const tutoringType = getSavedTutoringType();
  const [online, setOnline] = useState(tutoringType === 'ONLINE');
  const [selectedCourseCategory, setSelectedCourseCategory] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('popular');
  const [selectedSubjectId, setSelectedSubjectId] = useState<string>('');
  const [selectedLevelId, setSelectedLevelId] = useState<string>('');
  const [subjectArrowStatus, setSubjectArrowStatus] = useState<boolean>(true);
  const [priceArrowStatus, setPriceArrowStatus] = useState<boolean>(true);
  const [selectedSubjectLevels, setSelectedSubjectLevels] = useState<Level[]>([]);
  const [savedPostcode, setSavedPostcode] = useState<string | undefined>(
    undefined
  );
  const [pricingMinMax, setPricingMinMax] = useState(isUAE ? [50, 400] : [20, 90]);
  const [isValidatingPostcode, setIsValidatingPostcode] = useState(false);
  const [savedExperienceLabel, setSavedExperienceLabel] = useState('');

  useEffect(() => {
    setOnline(tutoringType === 'ONLINE');
  }, [tutoringType]);

  const onSubjectLevelChanged = ({ subjectId, levelId }: { subjectId?: string; levelId?: string; }) => {
    if (subjectId) {
      const selectedSubject = subjectGroups
        .flatMap(e => e.subjects)
        .find(e => e.id === Number(subjectId));

      if (selectedSubject) {
        requestNewSearch();
        const firstLevel = selectedSubject.levels[0];
        setSelectedSubjectLevels(selectedSubject.levels);
        setSelectedLevelId(`${firstLevel.id}`);
        saveLevel(`${firstLevel.id}`);
        return;
      }
    }

    if (subjectId && selectedLevelId || levelId && selectedSubjectId) {
      requestNewSearch();
    }
  };

  // useEffect(() => {
  //   if (!defaultSubject) { return; }
  //   if (defaultSubject.id !== Number(selectedSubjectId)) {
  //     setSelectedSubjectLevels(defaultSubject.levels);
  //     setSelectedSubjectId(`${defaultSubject.id}`);
  //     saveSubject(`${defaultSubject.id}`);
  //   }
  // }, [defaultSubject]);

  // useEffect(() => {
  //   if (!defaultLevel) { return; }
  //   if (defaultLevel.id !== Number(selectedLevelId)) {
  //     setSelectedLevelId(`${defaultLevel.id}`);
  //   }
  // }, [defaultLevel]);

  useEffect(() => {
    // default English language when a user land the page
    setSelectedSubjectId('0');
    saveSubject('0');
    setSelectedLevelId('0');
    saveLevel('0');
    const postcode = getSavedPostcode();
    if (postcode) {
      setSavedPostcode(postcode);

      const tutoringType = getSavedTutoringType();

      if (tutoringType === 'ONLINE') {
        setSavedPostcode('');
      } else {
        setSavedPostcode(postcode);
      }
    }
    setSelectedCourseCategory(getCourseCategory()||'')
  }, []);

  useEffect(() => {
    const minExperienceString = getSavedMinExperience();
    const maxExperienceString = getSavedMaxExperience();

    if (minExperienceString && maxExperienceString) {
      const minExperience = parseInt(minExperienceString);
      const maxExperience = parseInt(maxExperienceString);

      const selectedExperience = experienceOptions.filter(
        option =>
          option.value[0] === minExperience && option.value[1] === maxExperience
      )[0];

      const { label } = selectedExperience;
      setSavedExperienceLabel(t(`${label}s`));
    }
  }, []);

  useEffect(() => {
    const minPriceString = getSavedMinPrice();
    const maxPriceString = getSavedMaxPrice();

    if (minPriceString && maxPriceString) {
      const minPrice = parseInt(minPriceString);
      const maxPrice = parseInt(maxPriceString);

      setPricingMinMax([minPrice, maxPrice]);
    }
  }, []);
  const onCategoryChange=(e:any) => {
    e.stopPropagation()
    const category= e.target.value
    saveCategory(category)
    setSelectedCategory(category);
    requestNewSearch();
  }
  var categoryData = [
    {name:'Trending',key:'popular'},
    {name:"Top Rated",key:"rating"},
  
];
var courseCategoryData = [
  {"key":"","title":"All","labelText":"All"},
  {"key":"academicTutoringSupport","title":"academic-tutoring-support","labelText":"Academic Tutoring & Support"},
  {"key":"languages","title":"languages","labelText":"Languages"},
  {"key":"steam","title":"steam","labelText":"STEAM"},
  {"key":"financialEconomicsLiteracy","title":"financial-economics-literacy","labelText":"Financial & Economics Literacy"},
  {"key":"socialSciences","title":"social-sciences","labelText":"Humanities"},
  {"key":"socialDevelopment","title":"social-development","labelText":"Personal Development & Mental Health"},
  {"key":"artMusic","title":"art-music","labelText":"Art & Music"},
  {"key":"itSoftware","title":"it-software","labelText":"IT & Software"},
  {"key":"businessEntrepreneurship","title":"business-entrepreneurship","labelText":"Business and Entrepreneurship"},
  {"key":"marketing","title":"marketing","labelText":"Marketing and Design"},
  {"key":"healthFitness","title":"health-fitness","labelText":"Health & Fitness"}
]
const onCourseCategoryChange=(e:any) => {
  e.stopPropagation()
  const category= e.target.value;
  saveCourseCategory(category);
  setSelectedCourseCategory(category);
  requestCourseSearch();
}
  const onSubjectChange=(e:any) => {
    e.stopPropagation()
    const subjectId= e.target.value
    saveSubject(`${subjectId}`)
    setSelectedSubjectId(subjectId);
    onSubjectLevelChanged({ subjectId });
  }
  var subjectData = [
    {name:"All Subjects",id:"0",slug:""},
    {name:"English Language",id:"40",slug:"english-language"},
    {name:'Fine Art',id:'29',slug:"fine-art"},
    {name:"4+ Exams and Prep",id:"153",slug:"4-exams-and-prep"},
    {name:"Theology",id:"43",slug:"theology"},
    {name:"Japanese",id:"71",slug:"japanese"},
    {name:"Electronics",id:"81",slug:"electronics"},
    {name:"Maths",id:"74",slug:"maths"},
    {name:"Media Studies",id:"33",slug:"media-studies"},
    {name:"DT",id:"36",slug:"dt"},
    {name:"History",id:"41",slug:"history"},
    {name:"CAD Software",id:"144",slug:"cad-software"}
];

  const onPricingChange = (sliderValue: SliderValue) => {
    const values = [...(sliderValue as [number, number])];

    if (
      isValidPrice(values[0]) &&
      isValidPrice(values[1]) &&
      values[1] >= values[0]
    ) {
      setPricingMinMax(values);

      saveMinPrice(`${values[0]}`);
      saveMaxPrice(`${values[1]}`);

      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }

      timer.current = setTimeout(() => {
        requestNewSearch();
      }, 600);

    }
  };

  const isValidPrice = (price: number): boolean => {
    const isUKPriceValid =  price >= 20 && price <= 90 && /^\d+$/.test(`${price}`);
    const isUAEPriceValid = price >= 50 && price <= 400 && /^\d+$/.test(`${price}`);
    return isUAE ? isUAEPriceValid : isUKPriceValid
  };

  const subjectArrowChange=()=>{
    setSubjectArrowStatus(!subjectArrowStatus)
  }
  const priceArrowChange=()=>{
    setPriceArrowStatus(!priceArrowStatus)
  }

  const { t } = useTranslation()


  return (
    <Spin spinning={loading}>
      <div className={css.container}>
        <Form>
          <div>
            {activekey=='Tutor'&&<>
              <div className={css.topTitle}>{t('Browse by')}</div>
              <div className={css.categoryRadioWarp}>
                <Radio.Group onChange={onCategoryChange} value={selectedCategory}>
                    {
                      categoryData.map(category => {
                          return (
                            <Radio onClick={(e)=>{e.stopPropagation()}} value={category.key} key={category.key}  className={css.radioStyle}>
                              <span onClick={(e)=>{e.stopPropagation()}}>{category.name}</span>
                            </Radio>
                          );
                      })
                  }
                </Radio.Group>
              </div>
            </>}
            <div className={css.topTitle}>{t('Filter by')}</div>
            <div onClick={(e)=>{
              e.stopPropagation()
              subjectArrowChange()
              }} 
              className={css.subTitle}>
              <img style={subjectArrowStatus?{}:{'transform': 'rotate(180deg)'}} src={vectorarrow}  alt="vectorarrow" />
              {activekey=='Tutor'?t('Subjects'):t('Category')}
              </div>
            {activekey=='Tutor'&&subjectArrowStatus&&<div>
              <Radio.Group onChange={onSubjectChange} value={selectedSubjectId}>
                  {
                    subjectData.map(subject => {
                        return (
                          <Radio onClick={(e)=>{e.stopPropagation()}} value={subject.id} key={subject.id} className={css.radioStyle}>
                            <span onClick={(e)=>{e.stopPropagation()}}>{subject.name}</span>
                          </Radio>
                        );
                    })
                }
              </Radio.Group>
            </div>}
            {activekey=='Courses'&&subjectArrowStatus&&<div>
              <Radio.Group onChange={onCourseCategoryChange} value={selectedCourseCategory}>
                  {
                    courseCategoryData.map(subject => {
                        return (
                          <Radio onClick={(e)=>{e.stopPropagation()}} value={subject.key} key={subject.key} className={css.radioStyle}>
                            <span onClick={(e)=>{e.stopPropagation()}}>{subject.labelText}</span>
                          </Radio>
                        );
                    })
                }
              </Radio.Group>
            </div>}
           {activekey=='Tutor'&& <>
              <div onClick={(e)=>{
                e.stopPropagation()
                priceArrowChange()
              }} className={css.subTitle}>
                <img src={vectorarrow} style={priceArrowStatus?{}:{'transform': 'rotate(180deg)'}}  alt="vectorarrow" />
                Price
              </div>
              {priceArrowStatus&&<div onClick={(e)=>{e.stopPropagation()}}>
                <Slider
                  className='ant-priceSlider'
                  marks={isUAE ? uaeMarks : marks}
                  range
                  step={isUAE ? 50 : 10}
                  min={isUAE ? 0 : 10}
                  max={isUAE ? 450 : 100}
                  onChange={onPricingChange}
                  value={[pricingMinMax[0], pricingMinMax[1]]}
                  reverse={i18n.isAr}
                />
              </div>}
            </>}
            {activekey=='Tutor'&&
             <a onClick={(e)=>{e.stopPropagation()}} className={cn("btn-home",css.moreSubject)}  href={KEY_CONTENT_ROUTES.SEARCH_RESULTS}>
             {t('More subjects')}
             </a>
             }
          </div>
        </Form>
      </div>
    </Spin>
  );
}
