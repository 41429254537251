import React from 'react';
import AnimatedContainer from 'components/AnimatedContainer';
import FAQAccordion from 'components/FAQUkAccordion';
import { faq } from '../../constants/faq';
import css from './UkFaq.module.scss';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';

function FAQ() {
  const { t } = useTranslation()
  return (
    <AnimatedContainer className={css.wrapper}>
      <section className={css.container}>
        <div className={css.faqHeader}>
         {t('How Tutor House works FAQ')}
        </div>
          <div className={css.faqAccordion}>
            <FAQAccordion
              faq={faq}
              hideTitle
              noPadding
              classes={{
                block: css.accordionBlock,
                title: css.accordionTitle,
                wrapper: css.accordionWrapper
              }}/>
          </div>
          {/* <div className={css.contactBlock}>
            <p>{isUAE?t('You can speak to a member of our Customer Success Team during the week, from Mon-Fri (900 to 1700) Either email '):t('You can speak to a member of our Customer Success Team during our working hours, from Mon-Fri (900 to 1700) Either email ')}
            <a href="mailto:info@tutorhouse.co.uk">info@tutorhouse.co.uk</a>
            {t(' or call us on ')}
            <a href="tel:+442039500320">+4420 3950 0320</a>.
            {t(' We aim to respond to all queries within 24 hours')}</p>
          </div> */}
      </section>
    </AnimatedContainer>
  );
}

export default FAQ;
