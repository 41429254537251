import React from 'react';
import Collapse from 'components/Collapse/Collapse';
import { IFAQ } from './types';
import css from './Faq.module.scss';
import './ant.scss';
import dropDownArrow from 'static/img/dropArrowUk.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
const { Panel } = Collapse;

function FAQ({
  faq,
  hideTitle,
  noPadding,
  classes = {}
}: {
  faq: IFAQ[];
  hideTitle?: boolean;
  noPadding?: boolean;
  classes?: {
    title?: string;
    text?: string;
    block?: string;
    wrapper?: string;
  }
}) {
  const { t } = useTranslation()
  return (
    <div className={cn(css.wrapper, classes.wrapper)}>
      {!hideTitle && (
        <div className={css.title}>{t('Frequently Asked Questions')}</div>
      )}
      <div
        className={cn(css.questionList, {
          [css.noPadding]: noPadding
        })}
      >
        <Row gutter={36}>
          {faq.map((el,index) => (
            <Col lg={12} key={index}>
              <Collapse
              className={cn('ukant-question-list', classes.block)}
              expandIconPosition="right"
              bordered={false}
              expandIcon={({ isActive }) => (
                <img
                  src={dropDownArrow}
                  style={{ transform: `rotate(${isActive ? 1800 : 0}deg)` }}
                  alt=""
                />
              )}
             >
               <Panel key={el.name} header={el.name} className={classes.title}>
                    {Array.from(el.text).map((t, index) => (
                      <div className={cn(css.paragraph, classes.text)} key={index}>
                        {t}
                      </div>
                    ))}
                </Panel>
              </Collapse>
              </Col>
           ))}
        </Row>
        {/* <Collapse
          className={cn('ant-question-list', classes.block)}
          expandIconPosition="right"
          bordered={false}
          expandIcon={({ isActive }) => (
            <img
              src={dropDownArrow}
              style={{ transform: `rotate(${isActive ? 0 : 180}deg)` }}
              alt=""
            />
          )}
        >
            <Row gutter={36}>
             
              {faq.map(el => (
                 <Col lg={12}>
                <Panel key={el.name} header={el.name} className={classes.title}>
                  {Array.from(el.text).map((t, index) => (
                    <div className={cn(css.paragraph, classes.text)} key={index}>
                      {t}
                    </div>
                  ))}
                </Panel>
                </Col>
              ))}
           
             
            </Row>
            
        </Collapse> */}
      </div>
    </div>
  );
}

export default FAQ;
