import React, {
    useState,
    useEffect,
    useContext,
    useReducer,
    createContext,
    useRef,
    ReactElement } from 'react';
import cn from 'classnames';
import { Redirect ,Link} from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { History } from 'history';
import { ITutorProfileListItem, Level, SelectedSubject, SubjectGroup } from 'types';
import _ from 'lodash';
import { Form, Select, Slider, Spin, Input ,Col, Row,message,Modal} from 'antd';
import { AUTHENTICATED_ROUTES } from 'routing';
import GoogleReview from 'pages/Reviews/GoogleReview';
import css from './Find.module.scss';
import ukHomeCommon from 'styles/common.module.scss'
import { isUAE } from 'utils/env';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { position } from 'styled-system';
import { useTranslation } from 'react-i18next';
import FindFilters from './components/Filters';
import EndlessLearn from './components/EndlessLearn';
import TutorCard from 'components/TutorCardNew'
import CourseCard from 'components/CourseCard'
import { saveSearchTutorKeyword,saveCategory,getCategory,getCourseCategory } from 'utils/useSessionStorage';
import copy from 'copy-to-clipboard';
import BScroll from '@better-scroll/core'
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll'
import maskfilterbill from '../../../../static/img/maskfilterbillBlack.svg'
import maskfilter from '../../../../static/img/maskfilter.png'
import {
  KEY_CONTENT_ROUTES,
} from 'routing';
import reducer, {
    SearchResultsState,
    SearchResultsDispatchPayload,
    usePaginatorReducer,
  } from './reducer';
  import {
    requestSearchTutors,
    requestGetMoreTutors,
    SearchResultsActionType,
    requestGetSubjectList,
    changeSubject
  } from './actions';
import { getTutorProfile } from 'pages/Messaging/ChatsList/ChatItem/apis';
const { Search } = Input
interface ExploreProps {
  history: History;
}
export default function Find({ history }: ExploreProps) {

    const { t } = useTranslation()
    const [ courseLoading, setCourseLoading ] = useState(false);
    const [ activekey, setActivekey ] = useState('Tutor');
    const [searchName, setSearchName] = useState('');
    const [visibleMobileFilter, setVisibleMobileFilter] = useState(false);
    const [scrollObjArr, setScrollObjArr] = useState<any[]>([])
    const [courseList, setCourseList] = useState<any[]>([])
    const [{ loading, tutors, location, totalCount, subjectGroups, selectedSubject, selectedLevel, subjectLoading }, dispatch] = useReducer(
        reducer,
        useContext(
          createContext({
            loading: true,
            tutors: [],
            totalCount: 0,
            location: undefined,
            subjectGroups: [],
            selectedSubject: null,
            selectedLevel: null,
            subjectLoading: true
          })
        )
      ) as [
        SearchResultsState,
        React.Dispatch<{
          type: SearchResultsActionType;
          payload: SearchResultsDispatchPayload | null;
        }>
      ];
    const titlebtns=[
        {text:'Tutors',key:'Tutor'},
        // {text:'Courses',key:'Courses'}
    ]
    const tutorType=[
        {title:'Popular',key:'popular'},
        {title:'Top Rates',key:'rating'},
        {title:'Most Experienced',key:'experienced'},
        {title:'Lowest Price',key:'price'}
    ]
    const courseType=[
        {title:'Popular',key:'popular'},
        {title:'New',key:'new'},
        {title:'Top Rated',key:'topRated'},
        {title:'Best Value',key:'bestValue'}
    ]

    const onSearch = (value: string) => {
        saveSearchTutorKeyword(value)
        requestNewSearch()
      }
    const changePage = (page: number) => {
        // console.log(page)
    }
    const requestNewSearch = () => {
      changePage(1);
      requestSearchTutors(dispatch, subjectGroups);

      const defaultGroup = {
        id: 0,
        name: "Default",
        subjects: [{ id: 0, levels: [{ id: 0, level: "All Levels" } as Level], name: "All Subjects" } as SelectedSubject]
      } as SubjectGroup;

      subjectGroups.forEach(category => category.subjects.forEach(subject => {
        subject.levels.forEach(level => defaultGroup.subjects[0].levels.push({ id: level.id, level: level.level } as Level));
        subject.levels.unshift({ id: 0, level: "All Levels" } as Level);
      }));

      defaultGroup.subjects[0].levels = _.uniqBy(defaultGroup.subjects[0].levels, 'id');
      subjectGroups.unshift(defaultGroup);
    };
    const tutorRequest = (data: any) => {
        requestSearchTutors(dispatch, data);
      };

    const goToProfile = (tutor: ITutorProfileListItem,clickType:string) => {
      const url = createLinkUrl(tutor, clickType);
    }

    const createLinkUrl = (tutor: ITutorProfileListItem, clickType: string) => {
      let level;

      if(selectedSubject && clickType === 'book') {
        const tutorprofilePromise = getTutorProfile(tutor.id).then(result => { return result } );

        tutorprofilePromise.then(tutorProfile => {
          const subject = tutorProfile.subjects.find(subject => subject.subject === selectedSubject!.id);
          level = subject ? subject.level : undefined;
          redirectURLCallback(`/tutor/${selectedSubject.id}/${level ? level : '0'}/${tutor.id}`, clickType);
        });
      } else {
        redirectURLCallback(`/tutor/${selectedSubject ? selectedSubject.id : '0'}/${selectedLevel ? selectedLevel.id : '0'}/${tutor.id}`, clickType);
      }
    }

    const redirectURLCallback = (url: string, clickType: string) => {
      if(clickType==='share'){
        const copyText=`${process.env.REACT_APP_TUTORHOUSE_WEB_BASE_URL}${url}`
        copy(copyText)
        message.success(t('Copied'))
      }else{
        if (url) {
          history.push({pathname:url,state:{clickType}});
        }
      }
    }

    const scrollmove=()=>{
      const elementObject=document.querySelectorAll('.scrollItem')
      const arrScroll=[]
      for(let i=0;i<elementObject.length;i++){
        if(elementObject[i]) {
         const bsObj= new BScroll(elementObject[i] as HTMLElement,{
            // scrollbar: true, //need to import 全部插件能力的 BetterScroll包 "better-scroll"
            probetype: 3,
            scrollX: true,
            click:true,
            mouseWheel: true,
            eventPassthrough:'vertical'
          })
          arrScroll.push(bsObj)
        }
      }
      setScrollObjArr(arrScroll)
    }
    const preMove=(obj:any)=>{
      obj.scrollTo(obj.x-480,0,300)
    }
    const nextMove=(obj:any)=>{
      obj.scrollTo(obj.x+480,0,300)
    }

    const getCourseList=async ()=>{
      const oktopiApiUrl=process.env.REACT_APP_OKTOPI_API_URL;
      const category=getCourseCategory()||''
      setCourseLoading(true)
      let xhr:any;
      xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && xhr.status == 200) {
          try {
            setCourseList(JSON.parse(xhr.responseText).data || [])
            setCourseLoading(false)
          } catch (err) {
            console.error(err);
          }
        }
      }
      xhr.open("get", `${oktopiApiUrl}homepage/searchMarketClazzList?categoryType=${category}`);
      xhr.send();
    }

    const mobileFilterIcon=()=>{
      window.scrollTo(0,360)
       setVisibleMobileFilter(!visibleMobileFilter)
    }

    useEffect(() => {
        requestGetSubjectList(dispatch);
        tutorRequest(subjectGroups);
        getCourseList();
        //@ts-ignore TagManager
        // window.dataLayer.push({ pageCategory: 'search-results' });
      }, [dispatch]);

    useEffect(() => {
      setTimeout(()=>{
        scrollmove()
      },5000)

      }, [tutors])
    useEffect(() => {
      return () => {
        saveSearchTutorKeyword('')
      }
    }, [])
    useEffect(() => {
    }, [visibleMobileFilter])

    const titleheader= (
      <div className={css.titleHeader}>
          <div className={css.findTextIcon}>
            <div className={css.exploreText}>{t('Find')}</div>
            <div onClick={mobileFilterIcon} className={cn(css.mobileFilterIcon)}>
              {activekey=='Tutor'&&<span>{t('Subjects')}</span>}
              {activekey=='Courses'&&<span>{t('Categories')}</span>}
              {/* visibleMobileFilter?maskfilterbill:maskfilter */}
              <img title="img" src={maskfilterbill}/>
            </div>
          </div>
          <div className={css.tabsWarp}>
          {titlebtns.map((item,index)=>{
          return <button
                    className={
                      cn({
                      [css.btnFilled]: true,
                      [css.active]: activekey==item.key
                      })}
                      onClick={() => {setActivekey(item.key)}}
                      key={index}
                    >
                    {t(item.text)}

                  </button>
          })}
          </div>
     </div>
    )

  return (
    // style={{"overflow": "hidden"}}
    <div id="findSection" className={cn(css.exploreContainer)}>
      <div className={css.findContent}>
        <div className={activekey=='Tutor'?css.tutorCardWarp:css.courseCardWarp}>
            <div className={css.searchWarp}>
            {titleheader}
              {/* <Search
                allowClear
                className={css.search}
                value={searchName}
                size='large'
                placeholder={activekey=='Tutor'?t('Search Tutor by name'):t('Search Courses by name')}
                onChange={(value) => setSearchName(value.target.value)}
                onSearch={(value) => onSearch(value)}
              /> */}

            </div>
          <div className={css.searchResults}>
            {visibleMobileFilter&&<div onClick={()=>{setVisibleMobileFilter(false)}} className={css.mobileFilter}>
              <FindFilters
                subjectGroups={subjectGroups}
                requestNewSearch={requestNewSearch}
                requestCourseSearch={getCourseList}
                defaultSubject={selectedSubject}
                defaultLevel={selectedLevel}
                loading={subjectLoading}
                activekey={activekey}
            /></div>}
            <>
              {activekey=='Tutor'&&((subjectLoading||loading)?<div className={cn('spinLoading',css.spinLoading)}><Spin spinning={subjectLoading||loading}></Spin></div>:tutors.length?
              <div>
                <Row gutter={[20,20]}>
                  {tutors.map((itemCard:ITutorProfileListItem,index)=>{
                    return (
                      <Col lg={12} key={index}>
                          <div className={css.itemCol}>
                            <TutorCard
                              tutor={itemCard}
                              viewTutor={goToProfile}
                            />
                        </div>
                      </Col>
                      )
                  })}
                </Row>
                {totalCount && totalCount > 12 && <Link to={`search-results?page=2`} className={css.loadMore}>{t('Load more')}</Link>}
              </div>
              :<h3 className={css.noTutors}>{t('No tutors found for this search')}</h3>)}
              {activekey=='Courses'&&(courseLoading?<div className={cn('spinLoading',css.spinLoading)}><Spin spinning={courseLoading}></Spin></div>:courseList.length?
              <Row gutter={[20,20]}>
                {
                  courseList.map((item,index)=>{
                    return (
                      <Col lg={8} md={12} key={index}>
                        <div className={css.itemCol}>
                          <CourseCard
                            course={item}
                          />
                        </div>
                      </Col>
                  )})
                }
              </Row>
              :<h3 className={css.noTutors}>{t('No courses found for this search')}</h3>)}
            </>
          </div>
        </div>
        <div  className={cn(css.deskFilter,activekey=='Tutor'?css.tutorDeskFilter:'')}>
          <FindFilters
              subjectGroups={subjectGroups}
              requestNewSearch={requestNewSearch}
              requestCourseSearch={getCourseList}
              defaultSubject={selectedSubject}
              defaultLevel={selectedLevel}
              loading={subjectLoading}
              activekey={activekey}
          />
        </div>
      </div>
      {/* <EndlessLearn tutors={tutors} goToProfile={goToProfile}/> */}
    </div>
  );
}
