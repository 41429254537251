import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
// import Video from './UkVideoLeft';
import { KEY_CONTENT_ROUTES } from '../../../../routing';
import css from './UkVideoExceptional.module.scss';
import { useTranslation } from 'react-i18next';
import { currency } from 'utils/env';
import VideoPlayer from 'components/VideoPlayer';
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';
export default function VideoExceptional() {
  const { t } = useTranslation()
  return (
    <div className={css.wrapper}>
      <div className={css.videoBlock}>
        <VideoPlayer
          src="https://tutorhouse-web-production.s3.eu-west-2.amazonaws.com/videos/Welcome_to_Tutor_House.mp4"
          previewVideo="https://tutorhouse-web-production.s3.eu-west-2.amazonaws.com/videos/Welcome_to_Tutor_House_preview.mp4"
        />
      </div>
      <div className={css.infoBlock}>
        <div className={css.info}>
          <span>{t('Exceptional tutors')}</span> {t('for as little as')} <span>{currency}20/hr.</span>
        </div>
        <Link to={KEY_CONTENT_ROUTES.SEARCH_RESULTS} onClick={() => {
            saveSubject("0");
            saveLevel("0");
            saveCountry("0");
          }}
        >
          <button className={cn('btn-primary', css.btn)}>
            {t('Browse All Tutors')}
          </button>
        </Link>
      </div>
    </div>
  )
}