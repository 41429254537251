import React, { useRef, useState } from 'react';
import css from './VideoPlayer.module.scss';

interface IVideoPlayerProps {
  src: string;
  banner?: string;
  previewVideo?: string;
}

function VideoPlayer({ src, banner, previewVideo }: IVideoPlayerProps) {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [previewPlaying, setPreviewPlaying] = useState(false);
  const [firstTimePlayed, setFirstTimePlayed] = useState(false);

  const videoPlayer = useRef<HTMLVideoElement>(null);
  const previewPlayer = useRef<HTMLVideoElement>(null);

  const togglePlayVideo = () => {
    if (videoPlayer.current) {
      if (!videoPlaying) {
        videoPlayer.current.play();
        if (!firstTimePlayed) setFirstTimePlayed(true);
      } else {
        videoPlayer.current.pause();
      }
      setVideoPlaying(!videoPlaying);
    }
  }

  const showPreview = previewVideo && !videoPlaying && !firstTimePlayed;

  const onMouseEnterHandler = () => {
    if (previewPlayer.current) {
      if (!previewPlaying) {
        previewPlayer.current.play();
      }
      setPreviewPlaying(true);
    }
  };

  const onMouseLeaveHandler = () => {
    if (previewPlayer.current) {
      previewPlayer.current.pause();
      setPreviewPlaying(false);
    }
  }

  return (
    <div
      onClick={togglePlayVideo}
      className={css.videoWrapper}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {
        showPreview
          && <video
          muted
          autoPlay={true}
          playsInline={true}
          ref={previewPlayer}
          className={css.preview}
          src={previewVideo}
          loop
        ></video>
      }
         <video
          playsInline={true}
          preload='none'
          onPause={() => setVideoPlaying(false)}
          onPlay={() => setVideoPlaying(true)}
          ref={videoPlayer}
          src={src}
          poster={banner}
          controls={videoPlaying}
          onEnded={() => {
            setVideoPlaying(false);
            setFirstTimePlayed(false);
          }}
          >
        </video>
      {!videoPlaying && <div className={css.playpause}></div>}
    </div>
  );
}

export default VideoPlayer;
