import { GET } from 'utils/useApi';
import {
  getSavedLevel,
  getSavedSubject,
  getSavedTutoringType,
  getSavedPostcode,
  getSavedMinPrice,
  getSavedMaxPrice,
  getSavedMinExperience,
  getSavedMaxExperience,
  saveTutoringType,
  saveSubject,
  saveLevel,
  getSavedSearchTutorKeyword,
  getCategory
} from 'utils/useSessionStorage';
import {
  SelectedSubjectResponse,
  LevelResponse,
  TutorSearchParams,
  PostcodeResponse,
  TutorSearchResponse,
  SubjectGroup,
  SubjectsResponse,
  Level,
  SelectedSubject
} from 'types';
import { SearchResultsDispatchPayload } from './reducer';
export enum SearchResultsActionType {
  SEARCH_TUTORS_REQUEST = 'SEARCH_TUTORS_REQUEST',
  SEARCH_TUTORS_SUCCESS = 'SEARCH_TUTORS_SUCCESS',
  SEARCH_TUTORS_ERROR = 'SEARCH_TUTORS_ERROR',
  GET_MORE_TUTORS_REQUEST = 'GET_MORE_TUTORS_REQUEST',
  GET_MORE_TUTORS_SUCCESS = 'GET_MORE_TUTORS_SUCCESS',
  GET_MORE_TUTORS_ERROR = 'GET_MORE_TUTORS_ERROR',
  SET_PAGE = 'SET_PAGE',
  GET_SUBJECTS_REQUEST = 'GET_SUBJECTS_REQUEST',
  GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_ERROR = 'GET_SUBJECTS_ERROR',
  CHANGE_SUBJECT = 'CHANGE_SUBJECT',
}

export const changeSubject = (dispatch: React.Dispatch<{
    type: SearchResultsActionType;
    payload: any;
  }>,
   { subject, level }: { subject: SelectedSubject | null, level: Level | null }) => {

    if (subject) {
      saveSubject(`${subject.id}`)
    }
    if (level) {
      saveLevel(`${level.id}`);
    }
    return dispatch({
      type: SearchResultsActionType.CHANGE_SUBJECT,
      payload: {
        subject,
        level,
      }
    });
}

export const requestGetSubjectList = async (
  dispatch: React.Dispatch<{
    type: SearchResultsActionType;
    payload: any;
  }>,
  // onSuccess?: (data: SubjectGroup[]) => void
): Promise<void> => {
  const {
    GET_SUBJECTS_REQUEST,
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_ERROR
  } = SearchResultsActionType;

  dispatch({
    type: GET_SUBJECTS_REQUEST,
    payload: null
  });

  try {
    const useToken = false;
    const result = (await GET('v3/subjects/', useToken)) as SubjectsResponse;

    const subjectGroups = result.data;
    const subjectId = getSavedSubject() || (subjectGroups && subjectGroups[0].subjects && subjectGroups[0].subjects[0].id);
    const levelId = getSavedLevel() || (subjectGroups && subjectGroups[0].subjects && subjectGroups[0].subjects[0].levels && subjectGroups[0].subjects[0].levels[0].id);

    saveSubject(`${subjectId}`)
    saveLevel(`${levelId}`)

    const selectedSubject = subjectGroups
      .flatMap(e => e.subjects)
      .find(e => e.id === Number(subjectId));

    let selectedLevel;

    if (selectedSubject) {
      selectedLevel = selectedSubject.levels.find(e => e.id === Number(levelId));
    }

    dispatch({
      type: GET_SUBJECTS_SUCCESS,
      payload: {
        subjectGroups,
        selectedSubject,
        selectedLevel
      }
    });
    // if (onSuccess) {
    //   onSuccess(subjectGroups);
    // }
  } catch (err) {
    dispatch({
      type: GET_SUBJECTS_ERROR,
      payload: null
    });
  }
};

export const requestSearchTutors = async (
  dispatch: React.Dispatch<{
    type: SearchResultsActionType;
    payload: any;
  }>,
  subjectGroups: SubjectGroup[],
): Promise<void> => {
  const {
    SEARCH_TUTORS_REQUEST,
    SEARCH_TUTORS_SUCCESS,
    SEARCH_TUTORS_ERROR
  } = SearchResultsActionType;

  const MATH_ID = '74';
  const MATH_LEVEL = '3';

  const subjectId = getSavedSubject() || MATH_ID;
  const levelId = getSavedLevel() || MATH_LEVEL;
  const postcode = getSavedPostcode();
  const tutoringType = getSavedTutoringType();
  const minPrice = getSavedMinPrice();
  const maxPrice = getSavedMaxPrice();
  const minExperience = getSavedMinExperience();
  const maxExperience = getSavedMaxExperience();
  const keyword = getSavedSearchTutorKeyword();
  const category = getCategory();

  let selectedSubject;

  if (subjectId) {
    selectedSubject = subjectGroups
      .flatMap(e => e.subjects)
      .find(e => e.id === Number(subjectId));
  }

  let selectedLevel;

  if (selectedSubject) {
    selectedLevel = selectedSubject.levels.find(e => e.id === Number(levelId));
  }

  dispatch({
    type: SEARCH_TUTORS_REQUEST,
    payload: {
      selectedSubject,
      selectedLevel
    },
  });

  try {
    const useToken = true;

    const params: TutorSearchParams = {
      max_price: maxPrice ? parseInt(maxPrice) : 100,
      min_price: minPrice ? parseInt(minPrice) : 0,
      max_experience: maxExperience ? parseInt(maxExperience) : 100,
      min_experience: minExperience ? parseInt(minExperience) : 0,
      postcode: 'Online',
      search: keyword,
      category
    };

    params.subject_id = selectedSubject && selectedSubject.id !== 0 ? String(selectedSubject.id) : '';
    params.level_id = selectedLevel && selectedLevel.id !== 0 ? String(selectedLevel.id) : '';

    if (tutoringType === 'ONLINE') {
      params.online = true;
    } else {
      params.postcode = postcode;
      params.online = false;
    }
    const query = new URLSearchParams(params as any);
    const searchResult = (await GET(
      `v2/tutor/?${query}`,
      useToken
    )) as TutorSearchResponse;
    const { data } = searchResult;
    const { results, count } = data;
    // const  results = searchResult;

    let location = undefined;
    if (tutoringType === 'ONLINE') {
      location = 'Online';
    } else {
      if (postcode) {
        const locationResult = (await GET(
          `v2/postcodes/find_region?postcode=${postcode.replace(' ', '')}`,
          useToken
        )) as PostcodeResponse;
        location = locationResult.data.constituency;
      }
    }

    dispatch({
      type: SEARCH_TUTORS_SUCCESS,
      payload: {
        tutors: results,
        totalCount: count
      }
    });

  } catch (err) {
    dispatch({
      type: SEARCH_TUTORS_ERROR,
      payload: null
    });
  }
};

export const requestGetMoreTutors = async (
  dispatch: React.Dispatch<{
    type: SearchResultsActionType;
    payload: SearchResultsDispatchPayload | null;
  }>,
  pageNumber: number | undefined
): Promise<void> => {
  const {
    GET_MORE_TUTORS_REQUEST,
    GET_MORE_TUTORS_SUCCESS,
    GET_MORE_TUTORS_ERROR
  } = SearchResultsActionType;

  dispatch({
    type: GET_MORE_TUTORS_REQUEST,
    payload: null
  });

  if (!pageNumber) {
    dispatch({
      type: GET_MORE_TUTORS_SUCCESS,
      payload: {
        tutors: [],
      }
    });
    return;
  }

  const subjectId = getSavedSubject();
  const levelId = getSavedLevel();
  const postcode = getSavedPostcode();
  const tutoringType = getSavedTutoringType();
  const minPrice = getSavedMinPrice();
  const maxPrice = getSavedMaxPrice();
  const minExperience = getSavedMinExperience();
  const maxExperience = getSavedMaxExperience();
  const keyword = getSavedSearchTutorKeyword();

  try {
    const useToken = false;
    const subjectResult = (await GET(
      `v2/subjects/${subjectId}`,
      useToken
    )) as SelectedSubjectResponse;
    const levelResult = (await GET(
      `v2/teachinglevels/${levelId || 3}`,
      useToken
    )) as LevelResponse;

    const subjectSlug = subjectResult.data.slug;
    const levelSlug = levelResult.data.slug;


    const online = tutoringType === 'ONLINE';
    const params = {
      subject: `${subjectSlug}-${levelSlug}`,
      max_price: maxPrice,
      min_price: minPrice,
      max_experience: maxExperience,
      min_experience: minExperience,
      postcode: online ? 'Online' : postcode,
      online: online,
      search: keyword,
      page: pageNumber || undefined,
    };

    const query = new URLSearchParams(params as any);
    const searchResult = (await GET(
      `v2/tutor?${query}`,
      useToken
    )) as TutorSearchResponse;
    const { data } = searchResult;
    const  {results} = data;

    dispatch({
      type: GET_MORE_TUTORS_SUCCESS,
      payload: {
        tutors: results
      }
    });
  } catch (err) {
    dispatch({
      type: GET_MORE_TUTORS_ERROR,
      payload: null
    });
  }
};

export const setPage = (page: number) => ({
  type: SearchResultsActionType.SET_PAGE,
  page,
});
