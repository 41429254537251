import React from 'react';
import { Icon } from 'antd';

export default function UnfilledStar({
  fontSize = 1.5,
  distanceBetweenStars = 0,
  color = 'gold',
  onClick = () => {},
}: {
  fontSize?: number;
  distanceBetweenStars?: number;
  color?: string;
  onClick?: () => void;
}) {
  return (
    <Icon
      style={{ fontSize: `${fontSize}rem`, marginLeft: distanceBetweenStars }}
      twoToneColor={color}
      type="star"
      theme="twoTone"
      onClick={onClick}
    />
  );
}
