import React, { useEffect, useState, useRef } from 'react';
import css from './courseCard.module.scss';
import { Icon,Row,Col } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ITutorProfileListItem } from 'types';
import {  Text } from 'rebass';
import StarRating from 'components/StarRating';
import blankUser from 'static/img/blank_profile.png';
import calendarGreen from 'static/img/home/findTutor/calendarGreen.svg';
import calendarRed from 'static/img/home/findTutor/calendarRed.svg';
import star from 'static/img/home/findTutor/star.svg';
import { currency, isUAE } from 'utils/env';
import { priceForExperience } from 'utils';
import cn from 'classnames';
import labelCss from 'styles/modules/labels-with-icon.module.scss';
interface Props {
    course:any
}

export default function CourseCard(props: Props) {
  const {course}=props
  const { t } = useTranslation()
  const oktopiBaseUrl=process.env.REACT_APP_OKTOPI_BASE_URL
  const gotoCourseDetail=()=>{
    const category=parseCategoryWithDash(course.categoryType);
    const classURL=`course/${category}/${course.slug}`
    window.open(`${oktopiBaseUrl}${classURL}`,'_self');
  }

  const parseCategoryWithDash= (category:string)=> {
    const cat = {
      academicTutoringSupport: 'academic-tutoring-support',
      languages: 'languages',
      steam: 'steam',
      financialEconomicsLiteracy: 'financial-economics-literacy',
      socialSciences: 'social-sciences',
      physicalEducation: 'physical-education',
      socialDevelopment: 'social-development',
      artMusic: 'art-music',
      itSoftware: 'it-software',
      businessEntrepreneurship: 'business-entrepreneurship',
      financeAccounting: 'finance-accounting',
      officeProductivity: 'office-productivity',
      design: 'design',
      marketing: 'marketing',
      lifeStyle: 'life-style',
      photographyVideo: 'photography-video',
      healthFitness: 'health-fitness',
    }
    return cat[category] || 'academic-tutoring-support';
  }
  const dateFormat=(dateTime:any)=>{
    var dateT=new Date(dateTime)
    var years=dateT.getFullYear()
    var monthArr=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    var month = monthArr[dateT.getMonth()]; //得到月份
    var date = dateT.getDate(); //得到日期
    var arr = new Array("Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", )
    var week = arr[dateT.getDay()]
    var hour=dateT.getHours()
    var minu=dateT.getMinutes()
    
    return `${week}, ${month} ${date}, ${years}`
  }
    const initials=(user: any)=> {
        const { firstName = '', lastName = '' } = user
        const inits = []
        if(user && firstName && lastName) {
            inits.push(lastName?firstName[0]: '')
            inits.push(lastName?lastName[0] : '')
            return inits.join('').toUpperCase()
        } else {
        return '-'
        }
    }


  return (
    <div  
        className={css.courseCarWrap} 
        onClick={(e)=>{
            e.stopPropagation();
            gotoCourseDetail();
        }}
        >
        <div className={css.schoolWarp}>
            <div className={css.schoolLogo}>
            {!!course.schoolThumbnail&&<img src={course.schoolThumbnail} alt="oktopi" />}
            {!course.schoolThumbnail&&<span>{course.schoolName&&course.schoolName[0]}</span>}
            </div>
            <div className={css.schoolName}>{course.schoolName}</div>
        </div>
        <div className={css.courseNameWarp}  style={{'background':`url(${course.thumbnail?course.thumbnail.replace(/^oklocal:\/\//, oktopiBaseUrl):""}),lightgray 50%/cover no-repeat`,'backgroundPosition':'center'}}>
            <div className={css.mask}></div>
            <div className={css.courseName}>
                {!!course.classRating&&<div className={css.rating}>
                    <img src={star} alt=""/>
                    <span> {course.classRating.toFixed(1)}</span>
                </div>}
                {course.name} 
            </div>
        </div>
        <div className={css.courseOwn}>
            <div className={css.avatar}>
            {!!course.teachers[0].avatar&&<img src={course.teachers[0].avatar} alt="oktopi" />}
            {!course.teachers[0].avatar&&<span>{initials(course.teachers[0])}</span>}
            </div>
            <div>
                <div className={css.role}>Teacher</div>
                <div className={css.ownName}>{course.teachers[0].firstName} {course.teachers[0].lastName?course.teachers[0].lastName:''}</div>
            </div>
            <div className={css.free}>{course.free?'FREE':`${course.currency} ${((course.totalPrice||0)/100).toFixed(2)}`}</div>
        </div>
        <div className={css.courseShedule}>
            {course.classScheduleType==='schedule'&&
            <>
                <div>
                    <img src={calendarGreen} alt='' />
                </div>
                <div className={css.date}>
                    <div>
                        <div>{course.startTime ?dateFormat(course.startTime) :''}</div>
                        <div>{course.endTime ? dateFormat(course.endTime):''}</div>
                    </div>
                </div>
            </>
            }
            {course.classScheduleType!=='schedule'&&
            <>
            <div>
                <img src={calendarRed} alt='' /> 
            </div>
            <div className={css.date}>
                <div className={css.openClass}>Open Class</div>
            </div>
            </>
            }
            <a className={css.detailBtn} onClick={(e)=>{
                e.stopPropagation();
                gotoCourseDetail();
            }}>Details</a>
        </div>
    </div>
  );
}
