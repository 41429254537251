import fatimaAvatar from '../../../static/img/home/topTutorSubjects/fatima-avatar.svg';
import joshAvatar from '../../../static/img/home/topTutorSubjects/josh-avatar.svg';
import louiseAvatar from '../../../static/img/home/topTutorSubjects/louise-avatar.svg';
import thomasAvatar from '../../../static/img/home/topTutorSubjects/thomas-avatar.svg';
import eileenAvatar from '../../../static/img/home/topTutorSubjects/eileen-avatar.svg';
import i18n from 'i18next';

interface ItopTutors {
  image: string;
  title: string;
  subject: string;
  experience: number;
  price: number;
  mobilePrice?: number;
}

interface ITopSubjects {
  title: string;
  link: string;
}

export const topTutors: ItopTutors[] = [
  {
    image: fatimaAvatar,
    title: i18n.t('Fatima G'),
    subject: i18n.t('Maths Tutor'),
    experience: 3,
    price: 30
  },
  {
    image: joshAvatar,
    title: i18n.t('Josh J'),
    subject: i18n.t('Physics Tutor'),
    experience: 35,
    price: 40
  },
  {
    image: louiseAvatar,
    title: i18n.t('Louise M'),
    subject: i18n.t('English Tutor'),
    experience: 2,
    price: 50
  },
  {
    image: thomasAvatar,
    title: i18n.t('Thomas H'),
    subject: i18n.t('History Tutor'),
    experience: 6,
    price: 60,
    mobilePrice: 50
  },
  {
    image: eileenAvatar,
    title: i18n.t('Eileen R'),
    subject: i18n.t('Latin Tutor'),
    experience: 26,
    price: 70
  }
];

export const topSubjects: ITopSubjects[] = [
  {
    title: 'Maths',
    link: '/a/maths'
  },
  {
    title: 'English',
    link: '/a/english-language'
  },
  {
    title: 'Spanish',
    link: '/a/spanish'
  },
  {
    title: 'Biology',
    link: '/a/biology'
  },
  {
    title: 'Chemistry',
    link: '/a/chemistry'
  },
  {
    title: 'Physics',
    link: '/a/physics'
  },
  {
    title: 'Psychology',
    link: '/a/psychology'
  },
  {
    title: 'Economics',
    link: '/a/economics'
  },
  {
    title: 'Business Studies',
    link: '/a/business-studies'
  },
  {
    title: 'French',
    link: '/a/french'
  }
];
