import React from 'react';
import { Col, Row } from 'antd';
// import { tutorBenefits } from '../../constants/whyTutorHouse';
import AnimatedContainer from '../../../../components/AnimatedContainer';
import LazyLoad from 'react-lazyload';
import css from './UkBenefits.module.scss';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';
import match from 'static/img/priceMatch.svg';
import education from 'static/img/priceEducation.svg';
import noHiddenFee from 'static/img/priceNo-hidden-fee.svg';
import starIcon from 'static/img/priceStar.svg';
const Benefits = () => {
  const { t } = useTranslation()
  const tutorBenefits = [
    {
      img: education,
      title: 'TRAINED & EXPERIENCED TUTORS',
      description:
       <span>{t('We train our tutors to be more than just teachers')} <b>{t('Our training and interview process')}</b> {t('ensures students get experienced tuition and the educational support they deserveAll tutors are background checked')}</span>
    },
    {
      img: match,
      title: 'FREE FIRST LESSON',
      description:
        <span>{t('Tutor House offers a')} <b>{t('FREE 30-minute trial lesson')}</b> {t("with a tutorThis means you get to see if theyre right for you before committingOnce youve had your free call youll have the option to book discounted package lessons")}</span>
    },
    {
      img: starIcon,
      title:'INTERACTIVE LESSONS',
      description:
      <span>{t('With Tutor House you get access to our learning platform, which has a number of great features includingrecording your lesson, using the interactive whiteboard, video and audio and file sharing capabilities')}</span>
    },
    {
      img: noHiddenFee,
      title: 'NO HIDDEN FEES',
      description:
      <span>{t('With Tutor House youll never pay for anything other than the tuition sessions that you receive')} <b>{t('Become a member to save on session packages')}</b> {t('We also offer a 100% money-back guarantee')}</span>
    },
  ];
  return (
    <div className={css.wrapper}>
        <div className={css.benefitsH2}>{t('Tutor House Benefits')}</div>
        <Row gutter={40} className={css.benefitList}>
        {tutorBenefits.map(el => (
            <Col xs={24} sm={12} md={12} lg={6} key={el.title}>
            <AnimatedContainer className={css.benefit} key={el.title}>
                <div className={css.benefitHeader}>
                <LazyLoad>
                    <img src={el.img} alt={el.title} />
                </LazyLoad>
                <span>{t(el.title)}</span>
                </div>
                <div className={css.benefitDescription}>{el.description}</div>
            </AnimatedContainer>
            </Col>
        ))}
        </Row>
    </div>
  );
};

export default Benefits;
