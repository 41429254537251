export interface IGoogleReview {
  img: string;
  name: string;
  rating: number;
  time: string;
  positive: string;
  description: string;
  link: string;
}

export const GoogleReviewCarouselConfig = {
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  speed: 500,
  // arrows: true,
  initialSlide:0.5,
  responsive: [
    // {
    //   breakpoint: 1600,
    //   settings: {
    //     slidesToShow: 4,
    //     slidesToScroll: 1
    //   }
    // },
    {
      breakpoint: 1460,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const GoogleReviews: IGoogleReview[] = [
  {
    img: 'A',
    name: 'Arushee T',
    rating: 5,
    time: '4 month ago',
    positive: 'Communication, Professionalism, Quality, Value',
    description: 'Tutor House has provided exceptional support and motivation throughout my year of retaking. Right from the start , Alex Dyer ,the Director and all the tutors were incredibly encouraging of the career path I wanted to take .They were with...',
    link: 'https://www.google.com/maps/contrib/111967625050619438088/reviews/@51.5188962,-0.173369,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1'
  },
  {
    img: 'R',
    name: 'Rafael Garcia-Navarro',
    rating: 5,
    time: '8 month ago',
    positive: 'Communication, Professionalism, Quality, Value',
    description: 'Tutor House not only provided for our daughter a way forward at a difficult time, but a transformational experience. Over two years two fine tutors home-schooled our daughter in English Literature and Politics, which enabled her to complete...',
    link: 'https://www.google.com/maps/contrib/105758403859624649685/reviews?_ga=2.263313676.1059451040.1606316419-907029034.1606316419'
  },
  {
    img: 'M',
    name: 'Mark S',
    rating: 5,
    time: '4 month ago',
    positive: 'Communication, Professionalism, Quality, Value',
    description: 'Tutor House provided excellent teachers who helped my daughter achieve top A-Level results.  She is now on her way to Oxford...',
    link: 'https://www.google.com/maps/contrib/108853748148354641767/reviews/@51.4979475,-0.0825931,12z/data=!3m1!4b1!4m3!8m2!3m1!1e1'
  },
  {
    img: 'J',
    name: 'Juliette Corrin',
    rating: 5,
    time: '3 years ago',
    positive: 'Communication, Professionalism, Quality, Value',
    description: `Tutorhouse has turned my sons retakes into an experience he's so glad not to have missed.  His highly qualified tutors have been inspiring, enthusiastic, expert in their field, knowledgeable about exam board requirements and enormously generous...`,
    link: 'https://www.google.com/maps/contrib/104409903950599448449/reviews/@49.6143005,6.2149671,6z/data=!3m1!4b1!4m3!8m2!3m1!1e1'
  },
  {
    img: 'H',
    name: 'Hamssadvani Srirangeraj',
    rating: 5,
    time: '1 year ago',
    positive: 'Communication, Professionalism, Quality, Value',
    description: `The tutors at tutor house are well versed and passionate about their subjects. I used to hate chemistry but now I’m considering it at as my University degree. I got a D in chemistry last year but I've improved so much I’m able to aim for an A or A^*.`,
    link: 'https://www.google.com/maps/contrib/116082102219057979380/reviews?_ga=2.98588318.1059451040.1606316419-907029034.1606316419'
  }
];

