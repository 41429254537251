import React, { useEffect } from 'react';
import Home from './Home';
import { History } from 'history';
interface LoginProps {
  history: History;
}
export default function Homepage({ history }: LoginProps) {
  useEffect(() => {
    //@ts-ignore
    window.dataLayer.push({ pageCategory: 'home-page-view' });
  }, []);

  return <Home history={history}/>;
}
