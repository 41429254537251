import subjectSciences from 'static/img/classSubject/subjectSciences.svg';
import subjectUniversity from 'static/img/classSubject/subjectUniversity.svg';
import subjectart from 'static/img/classSubject/subjectart.svg';
import subjectbusiness from 'static/img/classSubject/subjectbusiness.svg';
import subjectenglish from 'static/img/classSubject/subjectenglish.svg';
import subjectentrance from 'static/img/classSubject/subjectentrance.svg';
import subjecthumanities from 'static/img/classSubject/subjecthumanities.svg';
import subjectictCompu from 'static/img/classSubject/subjectictCompu.svg';
import subjectlanguages from 'static/img/classSubject/subjectlanguages.svg';
import subjectmaths from 'static/img/classSubject/subjectmaths.svg';
import subjectmusic from 'static/img/classSubject/subjectmusic.svg';
import subjectscience from 'static/img/classSubject/subjectscience.svg';
import subjectsocialNon from 'static/img/classSubject/subjectsocialNon.svg';
import subjectspecialEducation from 'static/img/classSubject/subjectspecialEducation.svg';
const subjectData=[
        {"categary":"Arts","id":"3","icon":subjectart,"child":[
            {"name":'Fine Art',"id":'29','defaultLevel':"2","link":''},
            {"name":'Media Studies',"id":'33','defaultLevel':"2","link":''},
            {"name":'Photography',"id":'30','defaultLevel':"2","link":''},
            {"name":'Textiles',"id":'37','defaultLevel':"2","link":''},
            {"name":'Theatre Studies',"id":'139','defaultLevel':"2","link":''},
            {"name":'Music - Practical',"id":'34','defaultLevel':"2","link":''},
            {"name":'Performing-Arts',"id":'31','defaultLevel':"2","link":''}
            // {"name":'All Arts classes',"link":''},
        ]},
        {"categary":"Business","id":"8","icon":subjectbusiness,"child":[
            {"name":'Business Studies',"id":'98','defaultLevel':"2","link":''},
            {"name":'Finance',"id":'109','defaultLevel':"2","link":''},
            {"name":'Business Management',"id":'96','defaultLevel':"3","link":''},
            {"name":'Economics',"id":'108','defaultLevel':"2","link":''},
            {"name":'Travel and Tourism',"id":'97','defaultLevel':"2","link":''},
            {"name":'Global Perspective',"id":'95','defaultLevel':"2","link":''}
            // {"name":'All Business classes',"link":''},
        ]},
        {"categary":"English","id":"6","icon":subjectenglish,"child":[
            {"name":'English Language',"id":'40','defaultLevel':"2","link":''},
            {"name":'English Literature',"id":'39','defaultLevel':"2","link":''},
            {"name":'IELTS',"id":'126','defaultLevel':"8","link":''},
            {"name":'EFL (English as a Foreign Language)',"id":'106','defaultLevel':"17","link":''}
            // {"name":'All English classes',"link":''},
        ]},
        {"categary":"Entrance Exams","id":"14","icon":subjectentrance,"child":[
            {"name":'4+ Exams and Prep',"id":'153','defaultLevel':"1","link":''},
            {"name":'7+ and 8+ Entrance Exam Prep',"id":'154','defaultLevel':"1","link":''},
            {"name":"SAT's","id":'167','defaultLevel':"1","link":''},
            {"name":'11+ and 13+ Common Entrance',"id":'155','defaultLevel':"1","link":''},
            {"name":'Science',"id":'156','defaultLevel':"1","link":''}
            // {"name":'All Entrance Exams classes',"link":''}
        ]},
        {"categary":"Humanities","id":"1","icon":subjecthumanities,"child":[
            {"name":'Extended Essay',"id":'143','defaultLevel':"17","link":''},
            {"name":'T.O.K',"id":'141','defaultLevel':"4","link":''},
            {"name":'Theology',"id":'43','defaultLevel':"2","link":''},
            {"name":'Anthropology',"id":'44','defaultLevel':"3","link":''},
            {"name":'History',"id":'41','defaultLevel':"2","link":''}
            // {"name":'All Humanities classes',"link":''},
        ]},
        {"categary":"ICT and Computing","id":"10","icon":subjectictCompu,"child":[
            {"name":'CAD Software',"id":'144','defaultLevel':"7","link":''},
            {"name":'Coding',"id":'128','defaultLevel':"4","link":''},
            {"name":'ICT',"id":'86','defaultLevel':"2","link":''},
            {"name":'Computer Science',"id":'83','defaultLevel':"2","link":''}
            // {"name":'All ICT and Computing classes',"link":''},
        ]},
        {"categary":"Languages","id":"5","icon":subjectlanguages,"child":[
            {"name":'Japanese',"id":'71','defaultLevel':"2","link":''},
            {"name":'Farsi',"id":'60','defaultLevel':"2","link":''},
            {"name":'Spanish',"id":'51','defaultLevel':"2","link":''},
            {"name":'Greek',"id":'58','defaultLevel':"2","link":''},
            {"name":'Latin',"id":'72','defaultLevel':"2","link":''},
            {"name":'English Language',"id":'40','defaultLevel':"2","link":''}
            // {"name":'All Languages classesion',"link":''},
        ]},
        {"categary":"Maths","id":"2","icon":subjectmaths,"child":[
            {"name":'Statistics',"id":'75','defaultLevel':"3","link":''},
            {"name":'Maths',"id":'74','defaultLevel':"2","link":''},
            {"name":'Accounting',"id":'73','defaultLevel':"2","link":''},
            {"name":'Further Maths',"id":'170','defaultLevel':"2","link":''},
            {"name":'QTS Skills Test Training',"id":'171','defaultLevel':"4","link":''}
            // {"name":'All Maths classes',"link":''},
        ]},
        {"categary":"Music","id":"13","icon":subjectmusic,"child":[
            {"name":'Violin',"id":'149','defaultLevel':"7","link":''},
            {"name":'Trumpet',"id":'151','defaultLevel':"7","link":''},
            {"name":'Flute',"id":'164','defaultLevel':"7","link":''},
            {"name":'Piano',"id":'133','defaultLevel':"7","link":''},
            {"name":'Singing',"id":'136','defaultLevel':"7","link":''},
            {"name":'Baritone Horn',"id":'152','defaultLevel':"7","link":''}
            // {"name":'All Music classes',"link":''},
        ]},
        {"categary":"Health and Sciences","id":"4","icon":subjectscience,"child":[
            {"name":'Electronics',"id":'81','defaultLevel':"2","link":''},
            {"name":'Enviromental Science',"id":'131','defaultLevel':"3","link":''},
            {"name":'Chemistry',"id":'79','defaultLevel':"2","link":''},
            {"name":'Human Biology',"id":'78','defaultLevel':"2","link":''},
            {"name":'Science GCSE (Triple/Double Award)',"id":'76','defaultLevel':"2","link":''},
            {"name":'Food Technology',"id":'84','defaultLevel':"2","link":''},
            {"name":'Physical Education',"id":'87','defaultLevel':"2","link":''}
            // {"name":'Nutrition (Food Science)',"id":'82','defaultLevel':"20","link":''},
            // {"name":'Biology',"id":'77','defaultLevel':"20","link":''},
            // {"name":'Neuroscience',"id":'172','defaultLevel':"20","link":''},
            // {"name":'Physics',"id":'80','defaultLevel':"20","link":''}
            // {"name":'All Science classes',"link":''},
        ]},
        {"categary":"Social & Non-academic","id":"9","icon":subjectsocialNon,"child":[
            {"name":'Adobe Indesign',"id":'146','defaultLevel':"4","link":''},
            {"name":'Media Awareness',"id":'158','defaultLevel':"11","link":''},
            {"name":'Chess',"id":'132','defaultLevel':"11","link":''},
            {"name":'Drums',"id":'135','defaultLevel':"7","link":''},
            {"name":'Content Drafting',"id":'159','defaultLevel':"11","link":''},
            // {"name":'Job Interview and CV preparation',"id":'148','defaultLevel':"20","link":''},
            // {"name":'Adobe Photoshop',"id":'145','defaultLevel':"20","link":''},
            // {"name":'Public Speaking',"id":'160','defaultLevel':"20","link":''},
            // {"name":'Microsoft Office',"id":'166','defaultLevel':"20","link":''}
            // {"name":'All Social & Non-academic classes',"link":''},
        ]},
        {"categary":"Social Science","id":"7","icon":subjectSciences,"child":[
            {"name":'Law',"id":'93','defaultLevel':"2","link":''},
            {"name":'Health and Social Care',"id":'94','defaultLevel':"2","link":''},
            {"name":'Home Economics',"id":'91','defaultLevel':"2","link":''},
            {"name":'EPQ (Extended Project)',"id":'90','defaultLevel':"2","link":''}
            // {"name":'All Social Science classes',"link":''},
        ]},
        {"categary":"Special Education Needs & Support","id":"11","icon":subjectspecialEducation,"child":[
            {"name":'Dyslexia',"id":'110','defaultLevel':"2","link":''},
            {"name":'Dyspraxia',"id":'111','defaultLevel':"2","link":''},
            {"name":'Autism',"id":'113','defaultLevel':"2","link":''},
            {"name":'GCSE Homework Support',"id":'157','defaultLevel':"2","link":''},
            {"name":'ADHD/ADD',"id":'112','defaultLevel':"2","link":''},
            {"name":'Aspergers Syndrome',"id":'114','defaultLevel':"2","link":''}
            // {"name":'All Special Education Needs & Support classes',"link":''},
        ]},
        {"categary":"University and UCAS","id":"12","icon":subjectUniversity,"child":[
            {"name":'TSA (Thinking Skill Assessment)',"id":'138','defaultLevel':"4","link":''},
            {"name":'GMAT',"id":'117','defaultLevel':"4","link":''},
            {"name":'UKCAT',"id":'119','defaultLevel':"4","link":''},
            {"name":'Cambridge STEP exam',"id":'129','defaultLevel':"4","link":''},
            {"name":'GAMSAT',"id":'121','defaultLevel':"4","link":''},
            {"name":'Dissertation Help',"id":'161','defaultLevel':"4","link":''}
            // {"name":'University and UCAS classes',"link":''},
        ]}
    ]

export default subjectData