import { TutorProfile } from 'types';
import { GET } from 'utils/useApi';
import { AxiosResponse } from 'axios';

export async function getTutorProfile(id: number): Promise<TutorProfile> {
  const response = await GET(`v2/tutor/${id}/`, false);
  const { data } = response as AxiosResponse;

  if (data.selected_subject) {
    data.price = (data.selected_subject.price_in_cents / 100).toFixed(2);
  }
  return data as TutorProfile;
}